<template>
  <div class="m-product-short-info">
    <div class="product__header">
      <SfHeading
        :title="product.name | htmlDecode"
        :level="1"
        class="sf-heading--no-underline sf-heading--left"
      />
    </div>

    <div class="product__price-and-rating">
      <AArticul :articul="product.sku" />
      <AProductRatingShort
        :reviews-count="reviewsCount"
        :rating-product="ratingProduct"
        @click="handleOpenReviewsTab"
      />
    </div>

    <MCategoryNP
      v-if="!isAvailable"
      class="m-product-short-info__np"
      :is-product="true"
    />

    <div
      v-if="getPropertyProduct.length > 0"
      class="property-product-wrapper"
    >
      <AProductProperty
        v-for="(property, index) in getPropertyProduct"
        :key="index"
        :property-product="property"
      />
    </div>
    <div class="wrap">
      <div v-if="isAvailable" class="price">
        <AProductPrice
          v-if="product.type_id !== 'grouped'"
          :product="product"
          :custom-options="customOptions"
        />
        <div class="count">
          {{ getLabelWithPackage }}
        </div>
      </div>
      <SfButton
        v-else
        class="sf-button--outline btn-not-available"
      >
        {{ $t(outOfStockLabel) }}
      </SfButton>
      <NoSSR>
        <MProductAddToCart
          v-if="isInStock"
          class="product__add-to-cart"
          :product="product"
          :stock="productStock"
          @input="changeQuantity(product, $event)"
        />
      </NoSSR>
    </div>
    <div v-if="isThisProductDiscounted || theProductHasDiscountedProducts" class="markdown__section">
      <template v-if="theProductHasDiscountedProducts">
        <DiscountedProduct
          v-for="(item, index) in discountProducts"
          :product="item"
          :key="index"
        />
      </template>
      <template v-if="isThisProductDiscounted && isInStock">
        <div class="discounted-product__block">
          <span class="discounted-product__label">{{  $t('Reason for markdown:') }}</span>
          <span class="discounted-product__reason">{{ product['markdown_description'] }}</span>
        </div>
        <div class="discounted-product__footer">
          <SfLink :link="getLink(product.original_product.url_path)" class="sf-link--primary">
            {{ $t('View the product without discount')}}
          </SfLink>
        </div>
      </template>
    </div>
    <div v-if="product.varusPerfect" class="product__bonus">
      <ABonusBadge :varus-perfect="product.varusPerfect" />
    </div>
    <NoSSR>
      <ATextarea
        v-if="showComment && isInStock && isShowProductComment"
        v-model="comment"
        :placeholder="$t('Comment to the collector')"
        :alternative-placeholder="$t('Comment to the collector')"
        :id="product.id"
        :min-height="commentInputHeight"
        type="text"
        name="cart-comment"
        class="a-textarea--sm comment-placeholder"
        :class="{'is-saved': commentSaved}"
        @clear="onClear"
      />
    </NoSSR>
  </div>
</template>

<script>
import { SfHeading, SfButton, SfLink } from '@storefront-ui/vue';
import AProductRatingShort from 'theme/components/atoms/a-product-rating-short';
import AProductPrice from 'theme/components/atoms/a-product-price';
import AProductProperty from 'theme/components/atoms/a-product-property';
import ABonusBadge from 'theme/components/atoms/a-bonus-badge';
import AArticul from 'theme/components/atoms/a-articul';
import ATextarea from 'theme/components/atoms/a-textarea';
import { createSmoothscroll } from 'theme/helpers'
import { onlineHelper } from '@vue-storefront/core/helpers';
import MCategoryNP from 'theme/components/molecules/m-category-np.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import DeviceType from 'theme/mixins/DeviceType';
import debounce from 'lodash/debounce';
import { getProductQuantity } from 'theme/helpers/product';
import NoSSR from 'vue-no-ssr';
import { eSputnikEvent } from 'theme/helpers/es';
import DiscountedProduct from 'theme/components/product/discounted-product';

const CommentInputHeight = {
  Mobile: 90,
  Desktop: 75
}

export default {
  name: 'MProductShortInfo',
  components: {
    MCategoryNP,
    ATextarea,
    SfHeading,
    AProductRatingShort,
    AProductPrice,
    AProductProperty,
    ABonusBadge,
    AArticul,
    MProductAddToCart: () => process.browser ? import('theme/components/molecules/m-product-add-to-cart') : null,
    SfButton,
    SfLink,
    DiscountedProduct,
    NoSSR
  },
  mixins: [DeviceType],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      default: () => ({})
    },
    isNewPost: {
      type: Boolean,
      required: true
    },
    customOptions: {
      type: Object,
      default: () => ({})
    },
    reviews: {
      type: Array,
      default: () => []
    },
    productStock: {
      type: Object,
      default: () => ({})
    },
    isShowProductComment: {
      type: Boolean,
      default: true
    },
    discountProducts: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      commentSaved: false,
      comment: ''
    }
  },
  computed: {
    ...mapState({
      cartItems: state => state.cart.cartItems,
      isMicrocartOpen: state => state.ui.microcart
    }),
    ...mapGetters({
      getProductComments: 'cart/getProductComments',
      getReviewsProduct: 'product-review/getReviewsProduct',
      getCurrentCartHash: 'cart/getCurrentCartHash'
    }),
    reviewsCount () {
      return this.getReviewsProduct.length;
    },
    ratingProduct () {
      return this.product.hasOwnProperty('aw_review_rating') ? this.product.aw_review_rating / 20 : 0;
    },
    isOnline () {
      return onlineHelper.isOnline;
    },
    isAvailable () {
      return !this.isOnline ||
          (Boolean(this.productStock.max) && this.productStock.isInStock) ||
          !this.productStock.manageQuantity ||
          !this.isSimpleOrConfigurable
    },
    isSimpleOrConfigurable () {
      return ['simple', 'configurable'].includes(
        this.product.type_id
      );
    },
    isOutOfStockNP () {
      if (!this.productStock.isInStockSQPP) return false

      if (!this.isNewPost) return false

      return !this.product?.forNewPost
    },
    outOfStockLabel () {
      return this.isOutOfStockNP ? 'Out of stock NP' : 'Out of stock'
    },
    isProductInCart () {
      return Boolean(this.cartItems.find(item => item.id === this.product.id));
    },
    showComment () {
      return this.comment.length !== 0 || (
        this.isProductInCart &&
        this.isSimpleOrConfigurable
      )
    },
    checkComment () {
      return this.isMicrocartOpen
    },
    commentInputHeight () {
      return this.isMobile
        ? CommentInputHeight.Mobile
        : CommentInputHeight.Desktop
    },
    getLabelWithPackage () {
      return getProductQuantity(this.product);
    },
    isInStock () {
      return Boolean(this.productStock.max) && this.productStock.isInStock
    },
    getPropertyProduct () {
      const property = this.product?.property_data;

      return property !== undefined ? property : [];
    },
    isThisProductDiscounted () {
      return this.product?.['markdown_title'];
    },
    theProductHasDiscountedProducts () {
      return this.discountProducts.length > 0;
    }
  },
  watch: {
    comment (value) {
      this.saveProductComment(value)
    },
    isProductInCart (value) {
      if (!value) {
        this.comment = '';
      }
    },
    checkComment () {
      const candidate = this.getProductComments.find(comment => comment.sku === this.product.sku);

      this.comment = candidate?.comment || '';
    },
    $route: {
      deep: true,
      async handler () {
        await this.setProductComment();
        this.setCurrentComment();
      }
    }
  },
  methods: {
    ...mapActions({
      deleteProductComment: 'cart/deleteProductComment',
      setProductComment: 'cart/setProductComment',
      addProductComment: 'cart/addProductComment',
      updateQuantity: 'cart/updateQuantity'
    }),
    saveProductComment: debounce(function (value) {
      this.addProductComment({
        sku: this.product.sku,
        comment: value
      })
      this.commentSaved = true;
    }, 300),
    onClear () {
      this.comment = ''
      this.commentSaved = false;
      this.deleteProductComment(this.product.sku)
    },
    handleOpenReviewsTab () {
      const tabsElement = document.querySelector('#m-product-additional-info');
      if (!tabsElement) return;

      const reviewElement = tabsElement.querySelector('.m-product-tab_reviews');
      const reviewContent = tabsElement.querySelector('.m-product-tab__content_reviews');
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollToElement = this.isDesktop
        ? reviewElement
        : reviewContent;

      createSmoothscroll(
        scrollTop,
        scrollTop + scrollToElement.getBoundingClientRect().top
      );

      if (this.isDesktop) {
        reviewElement.click();
      }
    },
    async changeQuantity (product, newQuantity) {
      const diffLog = await this.updateQuantity({
        product: product,
        qty: newQuantity
      })

      if (diffLog.clientNotifications.length) {
        diffLog.clientNotifications.forEach(notificationData => {
          this.$store.dispatch(
            'notification/spawnNotification',
            notificationData,
            { root: true }
          )
        })
      }

      this.changeQuantityState(product.id, newQuantity)
      this.esputnikCartEvent()
    },
    async esputnikCartEvent () {
      eSputnikEvent('StatusCart', { productsInCart: this.cartItems, currentCartHash: this.getCurrentCartHash })
    },
    changeQuantityState (id, qty) {
      this.$bus.$emit('product-quantity-state-changed', { id, qty })
    },
    setCurrentComment () {
      const candidate = this.getProductComments.find(comment => comment.sku === this.product.sku);

      this.comment = candidate?.comment || '';
    },
    getLink (url) {
      return this.localizedRoute(`/${url}`);
    }
  },
  async mounted () {
    await this.setProductComment();
    this.setCurrentComment();
  },
  beforeMount () {
    this.changeQuantityState = debounce(this.changeQuantityState, 1000)
  },
  beforeDestroy () {
    this.changeQuantityState.cancel()
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/typography";
@import "~theme/css/breakpoints";
@import "~theme/css/mixins";

::v-deep .sf-heading__title {
  @include header-title;
}

.product {
  &__header {
    display: flex;
    justify-content: space-between;
    @include for-desktop {
      margin: 0 auto;
    }
  }
  &__drag-icon {
    animation: moveicon 1s ease-in-out infinite;
  }
  &__price-and-rating {
    margin: var(--spacer-xs) 0 var(--spacer-base);
    align-items: center;
    @include for-desktop {
      display: flex;
      justify-content: space-between;
      margin: var(--spacer-sm) 0 var(--spacer-lg) 0;
    }
  }
  &__description {
    color: var(--c-link);
    @include font(
      --product-description-font,
      var(--font-light),
      var(--font-base),
      1.6,
      var(--font-family-primary)
    );
  }
  &__add-to-cart {
    --button-font-size: var(--font-size-18);

    @media (max-width: $mobile-max) {
      width: 100%;

      ::v-deep .a-add-to-cart {
        width: 100%;
      }
    }
  }
}

.product__price-and-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product__bonus {
  margin-bottom: var(--spacer-40);

  @media (max-width: $mobile-max) {
    margin-bottom: var(--spacer-20);
  }
}

.m-product-short-info__np {
  margin-bottom: var(--spacer-20);
}

.price {
  ::v-deep {
    .sf-price__regular {
      font-weight: var(--font-medium);
      font-size: var(--font-size-30);
      line-height: 36px;
      color: var(--orange);

      @include only-mobile {
        font-size: var(--font-size-26);
        line-height: 31px;
        display: block;
      }
    }
    .sf-price__special {
      font-weight: var(--font-medium);
      font-size: var(--font-size-30);
      line-height: 36px;
      color: var(--orange);

      @include only-mobile {
        font-size: var(--font-size-26);
        line-height: 31px;
        display: block;
      }
    }

    .sf-price__old {
      font-size: var(--font-size-20);
      color: var(--dark-gray);

      @media (max-width: $mobile-max) {
        font-size: var(--font-size-14);
      }
    }
  }
}

//status for avaliable and add to cart button
.wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: $mobile-max) {
    flex-direction: column;
    margin-bottom: 35px;
  }

  .m-product-add-to-cart {
    ::v-deep{
      .sf-alert {
        display: none;
      }
    }
  }
}

.count {
  font-size: var(--font-size-12);
  color: var(--dark-gray);
}

@media (min-width: $desktop-min) {
  .product__price-and-rating {
    margin: 17px 0 24px 0;
  }
}

.comment-placeholder {
  background: var(--light-gray);
  font-size: var(--font-base);
  margin-top: 18px;

  &.is-saved {
    border: 1px solid var(--green);
  }

  ::v-deep .a-textarea__field {
    font-size: var(--font-size-15);
    line-height: var(--font-size-18);
  }
}

.btn-not-available {
  pointer-events: none;
  font-size: var(--font-lg);
  min-width: 195px;
  height: 56px;

  @media (max-width: $mobile-max) {
    width: 100%;
    margin-bottom: var(--spacer-10);
  }
}

@keyframes moveicon {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 30%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.property-product-wrapper {
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile-max) {
    width: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.markdown__section {
  display: flex;
  flex-direction: column;
}

.discounted-product {
  &__block {
    display: flex;
    flex-direction: column;
    margin: 0 0 10px;
    padding: 10px;
    background: var(--light-gray, #F3F4F6);
  }

  &__label {
    overflow: hidden;
    color: var(--black, #333);
    text-overflow: ellipsis;
    font-family: var(--font-family-primary), sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 5px;
  }

  &__reason {
    color: var(--red-main, #F93F3F);
    font-family: var(--font-family-primary), sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
