<template>
  <div class="attributes-images">
    <AProductAttributeImage
      v-for="(icon, index) in productIcons"
      :key="icon + index"
      :icon="icon.value"
      :label="icon.label"
    />
  </div>
</template>

<script>
import AProductAttributeImage from 'theme/components/atoms/a-product-attribute-image';

export default {
  name: 'MProductAttributesImages',
  components: {
    AProductAttributeImage
  },
  props: {
    productIcons: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.attributes-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 35px;
  gap: 30px;
}
</style>
