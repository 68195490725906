<template>
  <div class="m-product-mini-details">
    <SfImage
      class="m-product-mini-details__image"
      :src="product.sku | productImagePath(460, 460)"
      :alt="`Фото 2 - ${product.title}`"
      lazy
    />
    <div class="m-product-mini-details__content">
      <div class="m-product-mini-details__price">
        <div class="count">
          {{ getLabelWithPackage }}
        </div>
        <AProductPrice
          v-if="product.type_id !== 'grouped' && isInStock"
          :product="product"
          :custom-options="productCustomOptions"
        />
      </div>
      <NoSSR>
        <MProductAddToCart
          v-if="isInStock"
          class="m-product-mini-details__add-to-cart"
          :product="prepareProduct"
          :stock="productStock"
          :aria-label="$t('Add to cart')"
          @input="changeQuantity(prepareProduct, $event)"
        />
      </NoSSR>
    </div>
  </div>
</template>

<script>
import { getThumbnailPath } from '@vue-storefront/core/helpers';
import { SfImage } from '@storefront-ui/vue';
import AProductPrice from 'theme/components/atoms/a-product-price'
import { mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import { prepareCategoryProduct } from 'theme/helpers';
import NoSSR from 'vue-no-ssr';

export default {
  name: 'MProductMiniDetails',
  components: {
    SfImage,
    AProductPrice,
    NoSSR,
    MProductAddToCart: () => process.browser ? import('theme/components/molecules/m-product-add-to-cart') : null
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    productCustomOptions: {
      type: Object,
      default: () => ({})
    },
    productStock: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    productImage () {
      return getThumbnailPath(this.product.image, 230, 230)
    },
    getLabelWithPackage () {
      return this.product.quantityText
    },
    isInStock () {
      return Boolean(this.productStock.max) && this.productStock.isInStock
    },
    prepareProduct () {
      return prepareCategoryProduct(this.product)
    }
  },
  methods: {
    ...mapActions({
      updateQuantity: 'cart/updateQuantity'
    }),
    changeQuantity (product, newQuantity) {
      this.updateQuantity({
        product: product,
        qty: newQuantity
      });

      this.changeQuantityState(product.id, newQuantity)
    },
    changeQuantityState (id, qty) {
      this.$bus.$emit('product-quantity-state-changed', { id, qty })
    }
  },
  beforeMount () {
    this.changeQuantityState = debounce(this.changeQuantityState, 1000)
  },
  beforeDestroy () {
    this.changeQuantityState.cancel()
  }
};
</script>

<style lang="scss" scoped>
.m-product-mini-details {
  max-width: 15.625rem;
  margin: 0 auto 0 var(--spacer-88);

  &__image {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--spacer-12);

    ::v-deep img {
      padding: var(--spacer-10);
      max-width: 14.375rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__price {
    --price-old-font-size: var(--font-size-14);
    --price-special-color: var(--orange);
    --price-special-font-line-height: 1;
    --price-regular-color: var(--black);
    --price-old-color: var(--dark-gray);
    --price-old-font-line-height: 1;

    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: baseline;
    margin: 0 0 var(--spacer-12);

    .count {
      display: inline-flex;
      align-items: baseline;
      font-size: var(--font-size-12);
      color: var(--dark-gray);
    }
  }

  &__add-to-cart {
    width: 100%;

    ::v-deep {
      .a-add-to-cart {
        --button-width: 100%;
        --button-height: 40px;
        --button-font-size: var(--font-size-14);
        padding: 0;
      }

      .sf-alert {
        display: none;
      }
    }
  }
}

::v-deep .sf-price {
  display: flex;
  --price-align-items: baseline;
}
</style>
