<template>
  <div class="product">
    <div
      v-if="productPromotionBanners.length"
      class="mobile-only product__mobile-banner"
    >
      <MBannerCarousel
        :banners="productPromotionBanners"
        :is-responsive="true"
      />
    </div>

    <MProductGallery
      :gallery="gallery"
      :configuration="productConfiguration"
      :product="product"
      :product-stock="productStock"
    />

    <div class="product__info">
      <div class="product__desktop-banner desktop-only">
        <MBannerCarousel
          v-if="productPromotionBanners.length"
          :banners="productPromotionBanners"
          :is-responsive="true"
        />
      </div>
      <MProductShortInfo
        :is-loading="isLoading"
        :product="product"
        :custom-options="productCustomOptions"
        :reviews="reviews"
        :is-new-post="isNewPost"
        :product-stock="productStock"
        :discount-products="discountProducts"
      />
      <div>
        <NoSSR>
          <MProductOptionsGroup
            v-if="product.type_id === 'grouped'"
            :product-options="product.product_links"
          />
          <MProductOptionsBundle
            v-if="product.bundle_options && product.bundle_options.length > 0"
            :product="product"
          />
          <MProductOptionsCustom
            v-else-if="product.custom_options && product.custom_options.length > 0"
            :product="product"
          />
        </NoSSR>
      </div>
      <MProductAttributesImages
        v-if="showAttributesImages"
        :product-icons ="product.icons_data"
      />
      <MProductTopCharacteristics :product="product" />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import MProductGallery from 'theme/components/molecules/m-product-gallery';
import MProductShortInfo from 'theme/components/molecules/m-product-short-info';
import MProductTopCharacteristics from 'theme/components/molecules/m-product-top-characteristics';
import MProductAttributesImages from 'theme/components/molecules/m-product-attributes-images';
import { ModalList } from 'theme/store/ui/modals';
import { eSputnikEvent } from 'theme/helpers/es';
import MBannerCarousel from 'theme/components/molecules/m-banner-carousel'
import NoSSR from 'vue-no-ssr';

export default {
  name: 'OProductDetails',
  components: {
    MProductGallery,
    MProductShortInfo,
    MProductOptionsBundle: () => process.browser ? import('theme/components/molecules/m-product-options-bundle') : null,
    MProductOptionsCustom: () => process.browser ? import('theme/components/molecules/m-product-options-custom') : null,
    MProductOptionsGroup: () => process.browser ? import('theme/components/molecules/m-product-options-group') : null,
    MProductTopCharacteristics,
    MBannerCarousel,
    MProductAttributesImages,
    NoSSR
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    productPromotionBanners: {
      type: Array,
      default: () => []
    },
    isNewPost: {
      type: Boolean,
      required: true
    },
    reviews: {
      type: Array,
      default: () => []
    },
    product: {
      type: Object,
      default: () => ({})
    },
    productGallery: {
      type: Array,
      default: () => []
    },
    productConfiguration: {
      type: Object,
      default: () => ({})
    },
    productCustomOptions: {
      type: Object,
      default: () => ({})
    },
    productAttributes: {
      type: Array,
      default: () => []
    },
    productStock: {
      type: Object,
      default: () => ({})
    },
    discountProducts: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    gallery () {
      return this.productGallery.map(imageObject => ({
        id: imageObject.id,
        mobile: {
          url: imageObject.src,
          alt: this.product.name
        },
        desktop: {
          url: imageObject.src,
          alt: this.product.name
        }
      }));
    },
    availability () {
      return this.product.stock && this.product.stock.is_in_stock ? 'InStock' : 'OutOfStock'
    },
    showAttributesImages () {
      return this.product.icons_data && this.product.icons_data.length > 0
    }
  },
  watch: {
    product (newValue) {
      if (!newValue) return
      eSputnikEvent('ProductPage', this.product)
    }
  },
  mounted () {
    eSputnikEvent('ProductPage', this.product)
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    openSizeGuide () {
      this.openModal({ name: ModalList.SizeGuide })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.product {
  padding: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-min) {
    flex-direction: row;
    padding: 0 var(--spacer-16);
  }

  @media (min-width: $desktop-min) {
    padding: 0 var(--spacer-sm);
  }

  &__info {
    margin: var(--spacer-sm) 0 var(--spacer-xs);
    padding: 0 var(--spacer-10);

    @media (min-width: $tablet-min) {
      margin: var(--spacer-sm) auto var(--spacer-xs);
      padding: 0;
    }
  }

  &__add-to-cart {
    margin: var(--spacer-base) 0 0;

    @include for-desktop {
      margin-top: var(--spacer-sm);
    }
  }

  &__guide,
  &__compare,
  &__save {
    display: block;
    margin: var(--spacer-xl) 0 var(--spacer-base) auto;
  }

  &__mobile-banner, &__desktop-banner {
    margin: 0 0 px2rem(25);
  }

  //&-carousel {
  //  margin-bottom: px2rem(25);
  //
  //  @include for-mobile {
  //    display: none;
  //  }
  //
  //  ::v-deep {
  //    .sf-carousel__wrapper {
  //      padding-inline: 0;
  //
  //      .sf-banner__container {
  //        padding: 0;
  //        flex: 0 0 100%;
  //
  //        .sf-banner-plp__description {
  //          font-size: var(--font-size-14);
  //          font-weight: var(--font-medium);
  //          line-height: var(--spacer-19);
  //          margin-top: 0;
  //          margin-bottom: var(--spacer-5);
  //        }
  //
  //        .sf-button.sf-button-main {
  //          background-color: unset;
  //          color: var(--orange);
  //          font-weight: var(--font-normal);
  //          height: var(--spacer-25);
  //
  //          @media (min-width: $tablet-min) {
  //            height: var(--spacer-40);
  //          }
  //        }
  //      }
  //
  //      .sf-banner.sf-banner-plp {
  //        background-position: right;
  //        min-height: px2rem(90);
  //        height: px2rem(90);
  //        padding: px2rem(15) px2rem(24);
  //      }
  //    }
  //  }
  //}
}

.section {
  border-bottom: 1px solid #f1f2f3;
  padding-bottom: 10px;

  @include for-desktop {
    border: 0;
    padding-bottom: 0;
  }
}
</style>
