<template>
  <div class="m-product-characteristics">
    <div
      class="m-product-characteristics__row"
      v-if="attributes.length === 0 && product.pimBrandId"
    >
      <div class="m-product-characteristics__label">
        {{ $t("Trademark") }}
      </div>
      <div class="m-product-characteristics__value">
        <span>{{ getBrandName }}</span>
      </div>
    </div>
    <div
      class="m-product-characteristics__row"
      v-else
      v-for="(attribute, index) in attributes"
      :key="index"
    >
      <div class="m-product-characteristics__label">
        {{ attribute.label }}
      </div>
      <ABrandLink
        v-if="attribute.code === 'pim_brand_id'"
        custom-calss="m-product-characteristics__value"
        :prepare-value="prepareValue(attribute)"
        :brand-link="product"
      />
      <div
        v-else
        class="m-product-characteristics__value"
        v-html="prepareValue(attribute)"
      />
    </div>
    <p
      v-if="!product.pimBrandId && !attributes.length && !product.description"
      class="m-product-characteristics__no-characteristics"
    >
      {{ $t('Escape the description on the page. We promise to find and return to the place') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductCharacteristics from 'theme/store/product-extension/components/ProductCharacteristics';
import ABrandLink from 'theme/components/atoms/a-brand-link';
import config from 'config';
import escapeHTML from 'escape-html';

export default {
  name: 'MProductCharacteristics',
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [ProductCharacteristics],
  components: {
    ABrandLink
  },
  computed: {
    ...mapGetters({
      characteristicAttributes: 'product-extension/getCharacteristicAttributes',
      productAttributeLabel: 'product-extension/getProductAttributeLabel',
      characteristicAttributeCodes: 'product-extension/getAllCharacteristicAttributeCodes'
    }),
    getBrandName () {
      if (this.product.pimBrandId) {
        return this.productAttributeLabel[this.product.pimBrandId]
      }

      return undefined
    }
  },
  methods: {
    getLabelUnit (code) {
      return code === config.attributesCodes.weight ? this.$t('g.') : (code === config.attributesCodes.volumeOnPdp ? this.$t('ml.') : null)
    },
    prepareValue (attribute) {
      return attribute.code === 'weight' ? `${escapeHTML(attribute.value)} ${this.getLabelUnit(attribute.code)}` : `${escapeHTML(attribute.value)}`
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/store/product-extension/css/product-characteristics";
 .m-product-characteristics {
  &__row {
    .m-product-characteristics__value {
      display: flex;
      text-align: start;

      &--units {
        span {
          padding-left: var(--spacer-5);

          &:empty {
            padding: 0;
          }
        }
      }
    }
  }

   &__no-characteristics {
     margin: 0;
     font-size: var(--font-size-14);
     color: var(--black);
   }
}

</style>
