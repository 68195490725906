<template>
  <div
    class="a-product-rating-short"
    @click="$emit('click')"
  >
    <SfORating
      v-if="reviewsCount"
      :score="ratingProduct"
      :max="max"
      :summary-rating="true"
    />
    <p
      v-if="reviewsCount"
      class="rating-text"
    >
      ({{ reviewsCount }})
    </p>
    <button
      v-if="reviewsCount === 0"
      class="add-feedback"
    >
      {{ $t('Leave feedback') }}
    </button>
  </div>
</template>

<script>
import SfORating from 'theme/components/storefront-override/SFORating';

export default {
  components: {
    SfORating
  },
  props: {
    reviewsCount: {
      type: Number,
      default: 0
    },
    ratingProduct: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      max: 5
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.a-product-rating-short {
  display: flex;
  align-items: center;
  cursor: pointer;

  ::v-deep {
    .summary-rating {
      &-items {
        .sf-rating__icon {
          margin: 0 var(--spacer-2);
        }
      }
    }
  }

  .rating-text {
    margin-left: var(--spacer-10);
    color: var(--orange);
    font-size: var(--font-size-14);
  }

  .add-feedback {
    padding: 0;
    background: none;
    color: var(--orange);
    font-size: var(--font-size-14);
    border: none;
    cursor: pointer;
  }
}
</style>
