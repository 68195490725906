<template>
  <div class="review-item-wrapper">
    <div class="review-item">
      <div class="review-item-header">
        <p class="review-item__title">
          {{ review.nickname }}
        </p>
        <SfORating
          :score="getReview(review.rating)"
          :max="max"
        />
        <p class="review-item__date">
          {{ getDate(review.created_at) }}
        </p>
      </div>
      <p class="review-item__text">
        {{ review.content }}
      </p>
      <div class="review-item-action">
        <SfButton
          data-transaction-name="Review - Form"
          @click="triggerForm(review.id)"
          class="review-item__answer-button"
        >
          <svg
            class="review-item-action__answer-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
          >
            <path d="M6.66659 11.3333L13.3333 5.99996L6.66659 0.666626V3.99996C2.98459 3.99996 -8.01086e-05 6.98463 -8.01086e-05 10.6666C-8.01086e-05 10.8486 0.00658607 11.0286 0.0212526 11.2066C1.02659 9.29996 3.02792 7.99996 5.33325 7.99996H6.66659V11.3333Z" fill="#333333" />
          </svg>
          {{ $t('Answer') }}
        </SfButton>
        <div class="review-item-emotions">
          <SfButton
            class="review-item-emotions__button review-item-emotions__button_like"
            :class="{'review-item-emotions__button_disable' : clickDownvoteReview}"
            data-transaction-name="Review - Up Vote"
            @click="upvoteReview(review.id)"
          >
            <svg
              :class="{'active' : clickUpvoteReview}"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              view-box="0 0 16 14"
            >
              <path d="M1.33341 6.00001H3.33341V14H1.33341C1.1566 14 0.987034 13.9298 0.86201 13.8048C0.736986 13.6797 0.666748 13.5102 0.666748 13.3333V6.66668C0.666748 6.48987 0.736986 6.3203 0.86201 6.19528C0.987034 6.07025 1.1566 6.00001 1.33341 6.00001ZM4.86208 5.13801L9.12875 0.871347C9.18546 0.814476 9.26079 0.779991 9.34089 0.774234C9.421 0.768478 9.50049 0.791836 9.56475 0.840013L10.1334 1.26668C10.2913 1.38524 10.4106 1.54792 10.4763 1.73418C10.5419 1.92045 10.5508 2.12198 10.5021 2.31335L9.73342 5.33335H14.0001C14.3537 5.33335 14.6928 5.47382 14.9429 5.72387C15.1929 5.97392 15.3334 6.31306 15.3334 6.66668V8.06935C15.3336 8.24359 15.2996 8.41617 15.2334 8.57735L13.1701 13.5873C13.1197 13.7095 13.0343 13.8139 12.9245 13.8874C12.8147 13.9609 12.6855 14.0001 12.5534 14H5.33342C5.1566 14 4.98703 13.9298 4.86201 13.8048C4.73699 13.6797 4.66675 13.5102 4.66675 13.3333V5.60935C4.66679 5.43255 4.73705 5.26301 4.86208 5.13801Z" />
            </svg>
            <span
              v-if="review.upvotes !== '0'"
              :class="{'active' : clickUpvoteReview}"
            >
              {{ getUpvotes(review.upvotes) }}
            </span>
          </SfButton>
          <SfButton
            class="review-item-emotions__button review-item-emotions__button_dislike"
            :class="{'review-item-emotions__button_disable' : clickUpvoteReview}"
            data-transaction-name="Review - Down Vote"
            @click="downvoteReview(review.id)"
          >
            <svg
              :class="{'active' : clickDownvoteReview}"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              view-box="0 0 16 14"
            >
              <path d="M14.6666 7.99999H12.6666V-1.23978e-05H14.6666C14.8434 -1.23978e-05 15.013 0.0702257 15.138 0.19525C15.263 0.320274 15.3333 0.489843 15.3333 0.666655V7.33332C15.3333 7.51013 15.263 7.6797 15.138 7.80472C15.013 7.92975 14.8434 7.99999 14.6666 7.99999ZM11.1379 8.86199L6.87125 13.1287C6.81454 13.1855 6.73921 13.22 6.65911 13.2258C6.579 13.2315 6.49951 13.2082 6.43525 13.16L5.86658 12.7333C5.70865 12.6148 5.58936 12.4521 5.52375 12.2658C5.45814 12.0795 5.44915 11.878 5.49792 11.6867L6.26658 8.66665H1.99992C1.6463 8.66665 1.30716 8.52618 1.05711 8.27613C0.807061 8.02608 0.666585 7.68694 0.666585 7.33332V5.93065C0.666405 5.75641 0.700378 5.58383 0.766584 5.42265L2.82992 0.412654C2.88025 0.290509 2.96574 0.186073 3.07553 0.1126C3.18533 0.0391273 3.31448 -6.77109e-05 3.44658 -1.23978e-05H10.6666C10.8434 -1.23978e-05 11.013 0.0702257 11.138 0.19525C11.263 0.320274 11.3333 0.489843 11.3333 0.666655V8.39065C11.3332 8.56745 11.263 8.73699 11.1379 8.86199Z" />
            </svg>
            <span
              v-if="review.downvotes !== '0'"
              :class="{'active' : clickDownvoteReview}"
            >
              {{ getDownvotes(review.downvotes) }}
            </span>
          </SfButton>
        </div>
      </div>
    </div>
    <div
      v-if="!openForm && review.hasOwnProperty('comments') && review.comments.length > 0"
      class="answer-items"
    >
      <div
        v-for="item in review.comments"
        :key="item.id"
        class="answer-items-item"
      >
        <SfImage
          v-if="item.type === 'admin'"
          src="/assets/logo.svg"
          class="answer-items-item__logo"
        />
        <p
          v-else
          class="answer-items-item__title"
        >
          {{ item.nickname }}
        </p>
        <p class="answer-items-item__text">
          {{ item.content }}
        </p>
      </div>
    </div>
    <MAnswerReviewForm
      v-if="openForm"
      @answerReview="answerReview"
    />
  </div>
</template>

<script>
import { ModalList } from 'theme/store/ui/modals';
import { SfButton, SfImage } from '@storefront-ui/vue';
import SfORating from 'theme/components/storefront-override/SFORating';
import MAnswerReviewForm from 'theme/components/molecules/m-answer-review-form';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MReviewItem',
  components: {
    SfORating,
    SfButton,
    MAnswerReviewForm,
    SfImage
  },
  props: {
    review: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      max: 5,
      clickUpvoteReview: false,
      clickDownvoteReview: false,
      openForm: false,
      answerItemId: 0
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),

    getReview (rating) {
      return parseInt(rating) / 20;
    },

    getDate (date) {
      const formatDate = new Date(date)
      const day = formatDate.getDate()
      const month = formatDate.toLocaleString('en', { month: 'long' })
      const year = formatDate.getFullYear()
      return this.$t(`{day} ${month} {year}`, { day: day, year: year })
    },

    upvoteReview (id) {
      if (this.isLoggedIn) {
        this.clickUpvoteReview = !this.clickUpvoteReview;
        if (this.clickUpvoteReview) {
          this.$emit('upvoteReview', id);
        }
      } else {
        this.$store.dispatch('ui/hideBottomNavigation', false)
        this.openModal({ name: ModalList.Auth, payload: 'auth' })
      }
    },

    downvoteReview (id) {
      if (this.isLoggedIn) {
        this.clickDownvoteReview = !this.clickDownvoteReview;

        if (this.clickDownvoteReview) return this.$emit('downvoteReview', id);
      } else {
        this.$store.dispatch('ui/hideBottomNavigation', false)
        this.openModal({ name: ModalList.Auth, payload: 'auth' })
      }
    },

    triggerForm (id) {
      if (this.isLoggedIn) {
        this.openForm = !this.openForm
        this.answerItemId = id
      } else {
        this.$store.dispatch('ui/hideBottomNavigation', false)
        this.openModal({ name: ModalList.Auth, payload: 'auth' })
      }
    },

    answerReview (data) {
      const id = this.answerItemId;
      const review = {
        'review_id': id,
        'content': data.review,
        'nickname': data.firstname,
        'created_at': new Date()
      }

      this.$emit('answerReview', review);
      this.openForm = false
    },

    getUpvotes (votes) {
      return this.clickUpvoteReview ? Number(votes) + 1 : Number(votes)
    },

    getDownvotes (votes) {
      return this.clickDownvoteReview ? Number(votes) + 1 : Number(votes)
    }
  }
}

</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.review-item {
  width: 100%;
  margin-bottom: var(--spacer-10);
  padding: var(--spacer-20);
  background-color: var(--light-gray);
  box-sizing: border-box;

  &-header {
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 0;
    font-size: var(--font-size-16);
    color: var(--black);
    font-weight: var(--font-medium);
  }

  &__date {
    margin: 0 0 0 auto;
    font-size: var(--font-size-13);
    color: var(--dark-gray);
    font-weight: var(--font-normal);
  }

  ::v-deep {
    .sf-rating {
      margin-left: var(--spacer-20);

      &__icon {
        margin: 0 px2rem(1);
      }
    }
  }

  &__text {
    margin: var(--spacer-10) 0 0;
    font-size: var(--font-size-14);
    color: var(--black);
    font-weight: var(--font-normal);
    line-height: var(--spacer-22);
  }

  &-action {
    display: flex;
    margin-top: var(--spacer-15);

    &__answer-icon {
      margin-right: var(--spacer-10);
    }
  }

  &__answer-button {
    display: flex;
    align-items: center;
    padding: 0;
    background: none;
    font-size: var(--font-size-14);
    color: var(--orange);
    font-weight: var(--font-normal);
  }

  &-emotions {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;

    &__button {
      display: flex;
      align-items: center;
      background: none;
      margin-right: var(--spacer-15);
      padding: 0;

      &_disable {
        pointer-events: none;
        opacity: 0.5;
      }

      &_like {
        margin-right: var(--spacer-15);
      }

      span {
        margin-left: var(--spacer-5);
        font-size: var(--font-size-14);
        color: var(--black);

        &.active {
          color: var(--orange);
        }
      }

      svg {
        fill: var(--black);

        &.active {
          fill: var(--orange);
        }
      }

      &:hover {
        span {
          color: var(--orange);
        }

        svg {
          fill: var(--orange);
        }
      }
    }
  }
}

.answer-items {
  margin: 0 0 var(--spacer-30);
  &-item {
    margin: var(--spacer-15) 0 var(--spacer-20) var(--spacer-20);

    &__title {
      margin: 0;
      font-size: var(--font-size-16);
      color: var(--black);
      font-weight: var(--font-medium);
    }

    &__logo {
      width: px2rem(80);
    }

    &__text {
      margin: var(--spacer-10) 0 0;
      font-size: var(--font-size-14);
      color: var(--black);
      font-weight: var(--font-normal);
      line-height: var(--spacer-22);
    }
  }
}

</style>
