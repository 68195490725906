<template>
  <div class="property-product">
    <div
      v-for="property in preparedOptions"
      :key="property.product_id"
      class="property-product-item"
      :class="getPropertyClass(property)"
    >
      <SfLink
        v-if="getIsShowLink(property)"
       :link="getLink(property.url)"
      >
        {{ getLabel(property) }}
      </SfLink>
      <span v-else>
        {{ getLabel(property) }}
      </span>
    </div>
  </div>
</template>
<script>
import { SfLink } from '@storefront-ui/vue';
import { mapGetters } from 'vuex';

export default {
  name: 'AProductProperty',
  components: {
    SfLink
  },
  props: {
    propertyProduct: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      getInStockProductVariantsIds: 'product-extension/getInStockProductVariantsIds'
    }),

    preparedOptions () {
      const options = [...this.propertyProduct.options] || [];

      return options
        .map(option => ({
          ...option,
          isInStockInShop: this.getInStockProductVariantsIds.includes(Number(option.product_id))
        }))
        .sort((a, b) => {
          if (b.isInStockInShop !== a.isInStockInShop) {
            return b.isInStockInShop - a.isInStockInShop;
          }
          return a.product_id - b.product_id;
        });
    }
  },
  methods: {
    getLabel (property) {
      return property.label || '';
    },
    getLink (url) {
      return this.localizedRoute(`/${url}`);
    },
    getPropertyClass (property) {
      return [
        { 'property-product-item--active': property.is_current === '1' },
        { 'property-product-item--out-of-stock': !property.isInStockInShop }
      ]
    },
    getIsShowLink (property) {
      return property.is_current !== '1' && property.isInStockInShop
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.property-product {
  width: max-content;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--spacer-20);
  background-color: var(--orange-light-background);
  border-radius: var(--spacer-10);
  padding: var(--spacer-4);
  box-sizing: border-box;

  &-item {
    border-radius: var(--spacer-7);
    font-size: var(--font-size-14);
    color: var(--orange);
    cursor: pointer;

    @media (max-width: $mobile-max) {
      display: flex;
      justify-content: center;
      white-space: normal;
    }

    > * {
      padding: var(--spacer-12) var(--spacer-15) var(--spacer-11) var(--spacer-15);
      display: inline-block;
    }

    a {
      color: var(--orange);
      font-size: var(--font-size-14);
    }

    &--active {
      background-color: var(--white);
      color: var(--black);
      cursor: auto;
    }

    &--out-of-stock {
      color: var(--gray);
      cursor: auto;
    }
  }
}

</style>
