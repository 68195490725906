<template>
  <div class="articul">
    <span>{{ $t('Vendor code') }}:</span>
    <span>{{ articul }}</span>
  </div>
</template>

<style>
.articul {
  font-size: var(--font-size-13);
  color: var(--dark-gray);
}
</style>

<script>
export default {
  name: 'AArticul',
  props: {
    articul: {
      type: [Number, String],
      default: ''
    }
  }
};
</script>
