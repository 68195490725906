<template>
  <div class="product">
    <div class="product__image-wrapper">
      <SfOImage
        :src="product.sku | productImagePath(Number(imageSize.width), Number(imageSize.height))"
        :alt="`${product.name}`"
        :hide-loader="true"
        :width="imageSize.width"
        :height="imageSize.height"
        :lazy="true"
      />
    </div>
    <div class="product__block">
      <div class="product__content">
        <div v-if="isMobile" class="product__badges mobile-only">
          <Badges
            :product="product"
            :allow-discount-label="false"
            :allow-promotion-label="false"
            :allow-novelties-label="false"
            :allow-markdown-label="true"
          />
        </div>
        <span class="product__name">{{ product.name }}</span>
        <div class="product__price-block">
          <span class="original-price">{{ product.sqpp.price | price }}</span>
          <span class="special-price">{{ product.sqpp.special_price | price }}</span>
        </div>
        <span
            v-if="product['markdown_description']"
            class="product__markdown-reason"
        >
          {{ product['markdown_description'] }}
        </span>
      </div>
      <div class="product__aside">
        <div v-if="!isMobile" class="product__badges desktop-only">
          <Badges
            :product="product"
            :allow-discount-label="false"
            :allow-promotion-label="false"
            :allow-novelties-label="false"
            :allow-markdown-label="true"
          />
        </div>
        <SfLink
          :link="localizedRoute(`/${product.slug}`)"
          class="product__link sf-link--primary">
          {{ $t('View the product') }}
        </SfLink>
      </div>
    </div>
  </div>
</template>
<script>
import SfOImage from 'theme/components/storefront-override/SfOImage.vue';
import Badges from 'theme/components/product/badges.vue';
import { SfLink } from '@storefront-ui/vue';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import DeviceType from 'theme/mixins/DeviceType';

export default {
  name: 'DiscountedProduct',
  methods: { localizedRoute },
  mixins: [DeviceType],
  components: {
    Badges,
    SfOImage,
    SfLink
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      imageSize: {
        width: 59,
        height: 59
      }
    };
  }
}
</script>

<style lang="scss" scoped>
.product {
  display: flex;
  flex-direction: row;
  margin: 0 0 35px;
  padding: 10px;
  background: var(--light-gray, #F3F4F6);
  gap: 10px;

  @media only screen and (min-width: 768px) {
    margin: 0 0 10px;
  }

  &__image-wrapper {
    padding: 10px;
    background: var(--white, #FFF);
    width: 79px;
    height: 79px;
    box-sizing: border-box;
    flex-shrink: 0;
    position: relative;
    z-index: 0;

    ::v-deep {
      .sf-image img {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__image {
    z-index: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between
  }

  &__block {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      flex: 1 1 auto;
      justify-content: space-between;
    }
  }

  &__name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    color: var(--black, #333);
    text-overflow: ellipsis;
    font-family: var(--font-family-primary), sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
  }

  &__price-block {
    margin-bottom: 5px;
  }

  &__aside {
    @media only screen and (min-width: 768px) {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__markdown-reason {
    color: var(--red-main, #F93F3F);
    font-family: var(--font-family-primary), sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 15px;

    @media only screen and (min-width: 768px) {
      margin: 0;
      font-size: 13px;
    }
  }

  &__badges {
    position: relative;
    display: block;
    height: 20px;
    z-index: 0;

    ::v-deep {
      .product-label__badge-position-1 {
        top: 0;
      }
    }

    &.desktop-only {
      ::v-deep {
        .product-label__badge {
          right: 0;
        }
      }
    }
    &.mobile-only {
      margin-bottom: 10px;
    }
  }

  &__link {
    line-height: normal;
  }
}

.original-price {
  color: var(--dark-grey, #757679);
  font-family: var(--font-family-primary), sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: line-through;
}

.special-price {
  color: var(--orange, #FF6000);
  font-family: var(--font-family-primary), sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
