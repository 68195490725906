<template>
  <div id="product">
    <m-breadcrumbs />
    <json-ld-breadcrumb />

    <OProductDetails
      :is-loading="productLoader"
      :product="getCurrentProduct"
      :product-gallery="getProductGallery"
      :product-configuration="getCurrentProductConfiguration"
      :product-custom-options="getCurrentCustomOptions"
      :product-attributes="getCustomAttributes"
      :product-stock="productStock"
      :reviews="reviews"
      :is-new-post="isNewPost"
      :product-promotion-banners="productPromotionBanners"
      :discount-products="discountProducts"
    />

    <div
      :id="buyWithOrInsteadProductWidgetId"
      :key="buyWithOrInsteadProductWidgetId + getCurrentProduct.id"
    />
    <OSection
      v-show="buyWithOrInsteadBlockIsVisible"
      :title-heading="buyWithOrInsteadProductTitle"
      key="buy-with-product"
      is-centered
      :background="`#F3F4F6`"
      is-not-mobile-padding
      class="product-list-section buy-with-product"
    >
      <lazy-hydrate when-idle>
        <MProductsRelated
          :alt-counter-offset="2"
          :alt-name="getCurrentProduct.name"
          :prop-skus="esputnikBuyWithOrInsteadProductSku"
          arrow-color="white"
          type="buyWithOrInsteadProduct"
          only-in-stock
          @relatedProductsQty="insteadProductsQty"
          @visibilityUpdate="getBuyWithOrInsteadBlockIsVisible"
          :es-url-param="esUrlParamBuyWithOrInsteadProduct"
          :show-qty-permanently="true"
        />
      </lazy-hydrate>
    </OSection>
    <MProductAdditionalInfo
      class="product__additional-info"
      :is-loading="productLoader"
      :product="getCurrentProduct"
      :product-custom-options="getCurrentCustomOptions"
      :product-stock="productStock"
      :reviews="reviews"
      :key="getCurrentProduct.id"
    />

    <div
      :id="similarProductsWidgetId"
      :key="similarProductsWidgetId + getCurrentProduct.id"
    />
    <OSection
      v-show="similarBlockIsVisible"
      :title-heading="$t('More')"
      :orange-title-part="getCategoryName"
      :orange-title-link="getCategoryUrl"
      key="similar-products"
      is-centered
      is-image
      :background="`#E1ECDF`"
      is-not-mobile-padding
      class="product-list-section buy-instead-product"
      :class="relatedProductLength > 6 ? '' : 'buy-instead-product__without-arrows'"
    >
      <template #link>
        <SfLink :link="getCategoryUrl" class="sf-link--primary">
          {{ $t('All products of the category') }}
        </SfLink>
      </template>
      <lazy-hydrate when-idle>
        <MProductsRelated
          :alt-counter-offset="insteadProductsLength + 1"
          :alt-name="getCurrentProduct.name"
          :prop-skus="esputnikSimilarProductsSku"
          arrow-color="white"
          type="similarProduct"
          only-in-stock
          @relatedProductsQty="relatedProductsQty"
          @visibilityUpdate="getSimilarBlockIsVisible"
          :es-url-param="esUrlParamSimilarProducts"
          :show-qty-permanently="true"
        />
      </lazy-hydrate>
    </OSection>
    <OSection
      v-if="getProductTM.length"
      :title-heading="$t('Other categories TM')"
      :orange-title-part="brandName"
      :orange-title-link="brandLink"
      key="brands-offers"
      is-not-mobile-padding
      is-centered
      class="tm-section"
    >
      <MTmCarousel
        class="flex"
        :subcategories="getProductTM"
      />
    </OSection>
    <OSection
      v-if="false"
      :title-heading="$t('Cook with VARUS')"
      class="section"
    >
      <template #link>
        <SfLink link="#" target="_blank" class="sf-link--primary">
          {{ $t('All recipes') }}
        </SfLink>
      </template>
      <MRecipeCarousel class="flex" />
    </OSection>
    <OSection
      :title-heading="$t('Articles')"
      key="article"
      v-if="posts.length"
      is-centered
      is-not-mobile-padding
      class="o-section--article-product"
    >
      <template #link>
        <SfLink
          :link="localizedRoute('/blog')"
          class="sf-link--primary all-products-link"
        >
          {{ $t('All articles') }}
        </SfLink>
      </template>
      <lazy-hydrate when-idle>
        <mArticleCarouselProductPage :posts="posts" />
      </lazy-hydrate>
    </OSection>
    <JsonLdProduct :product="this.getCurrentProduct" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';
import { ReviewModule } from '@vue-storefront/core/modules/review';
import { registerModule } from '@vue-storefront/core/lib/modules';
import { onlineHelper, isServer } from '@vue-storefront/core/helpers';
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks';
import MProductsRelated from '../components/molecules/m-products-related';
import OProductDetails from '../components/organisms/o-product-details';
import { SfLink } from '@storefront-ui/vue';
import { filterChangedProduct } from '@vue-storefront/core/modules/catalog/events';
import MRecipeCarousel from '../components/molecules/m-recipe-carousel';
import MBreadcrumbs from '../components/molecules/m-breadcrumbs';
import { getEsputnikProductIds, getEsputnikUrl } from 'theme/helpers';
import OSection from 'theme/components/organisms/o-section';
import MTmCarousel from '../components/molecules/m-tm-carousel';
import get from 'lodash/get'
import MProductAdditionalInfo from 'theme/components/molecules/m-product-additional-info';
import config from 'config';
import JsonLdProduct from 'theme/components/json-ld/json-ld-product';
import JsonLdBreadcrumb from 'theme/components/json-ld/json-ld-breadcrumb';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import GoogleTagManager from 'theme/mixins/gtm';
import { metaProduct } from '../meta/product';
import mArticleCarouselProductPage from 'theme/components/molecules/m-article-carousel-product-page';
import modulesConfig from '$modules/config';
import { Logger } from '@vue-storefront/core/lib/logger';
import { getCurrentShopId } from 'theme/store/checkout/helpers';

export default {
  name: 'Product',
  components: {
    JsonLdBreadcrumb,
    JsonLdProduct,
    LazyHydrate,
    MProductsRelated,
    OProductDetails,
    MRecipeCarousel,
    OSection,
    MBreadcrumbs,
    MTmCarousel,
    MProductAdditionalInfo,
    mArticleCarouselProductPage,
    SfLink
  },
  mixins: [
    GoogleTagManager
  ],
  provide () {
    return {
      configurableOptionCallback: this.configurableOptionCallback,
      isProductPage: true
    };
  },
  data () {
    return {
      esputnikBuyWithOrInsteadProductSku: [],
      esputnikSimilarProductsSku: [],
      buyWithOrInsteadBlockIsVisible: false,
      similarBlockIsVisible: false,
      relatedProductLength: 0,
      insteadProductsLength: 0,
      esUrlParamBuyWithOrInsteadProduct: '',
      esUrlParamSimilarProducts: ''
    };
  },
  computed: {
    ...mapState({
      productLoader: state => state.ui.productLoader,
      getCurrentProduct: state => state.product.current,
      discountProducts: state => state.product.discountProducts
    }),
    ...mapGetters({
      getInStockProductVariantsIds: 'product-extension/getInStockProductVariantsIds',
      getCurrentCategory: 'category-next/getCurrentCategory',
      getProductGallery: 'product/getProductGallery',
      getCurrentProductConfiguration: 'product/getCurrentProductConfiguration',
      getOriginalProduct: 'product/getOriginalProduct',
      attributesByCode: 'attribute/attributeListByCode',
      getCurrentCustomOptions: 'product/getCurrentCustomOptions',
      getBreadcrumbsRoutes: 'breadcrumbs/getBreadcrumbsRoutes',
      shippingDetails: 'shipping/getShippingDetails',
      getProductPromotionBanners: 'promoted/getProductPromotionBanners',
      productAttributeLabel: 'product-extension/getProductAttributeLabel',
      posts: 'blog/getPosts',
      isNewPost: 'shipping-module/isCurrentNewPost'
    }),
    productPromotionBanners () {
      const hasPromotionBannerIds = this.getCurrentProduct?.promotion_banner_ids && this.getCurrentProduct.promotion_banner_ids.length;

      if (!hasPromotionBannerIds) return [];

      return this.getProductPromotionBanners.filter(banner => this.getCurrentProduct.promotion_banner_ids.includes(String(banner.id)));
    },
    productStock () {
      const max = this.getCurrentProduct?.stock?.manage_stock
        ? Number(this.getCurrentProduct.stock.qty)
        : null

      const isInStock = this.isNewPost
        ? this.getCurrentProduct?.forNewPost && this.getCurrentProduct.stock.is_in_stock
        : this.getCurrentProduct.stock.is_in_stock

      return {
        manageQuantity: this.getCurrentProduct.stock.manage_stock,
        isNewPost: this.isNewPost,
        isInStockSQPP: this.getCurrentProduct.stock.is_in_stock,
        isInStock,
        max
      }
    },
    getBrandLink () {
      return this.getCurrentProduct?.brand_data?.url_key
    },
    getProductTM () {
      return this.getCurrentProduct?.brand_data?.subcategories || []
    },
    brandName () {
      return this.getCurrentProduct?.brand_data?.name || ''
    },
    brandLink () {
      return this.localizedRoute(this.getCurrentProduct?.brand_data?.url_key || '')
    },
    getCategoryName () {
      const categories = this.getCurrentProduct?.category || []
      const productCategories = categories.filter(e => e.is_product_category && e.level > 2) || []
      return productCategories.length ? productCategories[productCategories.length - 1].name : ''
    },
    getCategoryUrl () {
      return this.getBreadcrumbsRoutes[this.getBreadcrumbsRoutes.length - 1]?.route_link
    },
    reviews () {
      const baseReviews = get(this.$store.state.review, 'items.items', [])
      return baseReviews.map((review) => ({
        author: review.nickname,
        date: review.created_at,
        message: `${review.title}: ${review.detail}`,
        rating: 1 // TODO: remove hardcode
      }))
    },
    isOnline () {
      return onlineHelper.isOnline;
    },
    getCustomAttributes () {
      return Object.values(this.attributesByCode)
        .filter(a => {
          return (
            a.is_visible &&
            a.is_user_defined &&
            (parseInt(a.is_visible_on_front) ||
              a.is_visible_on_front === true) &&
            this.getCurrentProduct[a.attribute_code]
          );
        })
        .sort((a, b) => {
          return a.attribute_id > b.attribute_id;
        });
    },
    buyWithOrInsteadProductTitle () {
      return this.getCurrentProduct.stock?.is_in_stock ? this.$t('Buy with this product') : this.$t('Buy instead of this product')
    },
    buyWithOrInsteadProductWidgetId () {
      return this.getCurrentProduct.stock?.is_in_stock ? config.esputnik?.widgetIds?.buyWithProduct : config.esputnik?.widgetIds?.insteadBuyProduct
    },
    similarProductsWidgetId () {
      return config.esputnik?.widgetIds?.similarProducts;
    },
    productVariantsIds () {
      const properties = this.getCurrentProduct?.property_data || [];

      if (!properties.length) return []

      return properties.flatMap(item => item.options.map(e => e.product_id));
    }
  },
  watch: {
    async 'shippingDetails.shopId' (newValue, oldValue) {
      if (!newValue || newValue === oldValue) return

      const slug = this.$route?.params?.slug || null;
      const product = await this.$store.dispatch('product/loadProduct', {
        slug: slug,
        includeFields: modulesConfig.singleProduct.includeFields,
        excludeFields: modulesConfig.singleProduct.excludeFields,
        skipCache: true
      });

      const discountProductIds = product[`markdown_items_${newValue}`] || []

      if (discountProductIds.length) {
        this.$store.dispatch('product/loadDiscountProducts', { ids: discountProductIds })
      } else {
        this.$store.dispatch('product/loadDiscountProducts', { ids: [] })
      }

      await this.$store.dispatch('product-extension/loadCharacteristicAttributes', { product })

      this.loadInStockVariants()
    },
    getCurrentProduct () {
      let currentProduct = this.getCurrentProduct;
      currentProduct.store = currentStoreView().storeCode;
      this.addItemToRecentlyViewedItems(currentProduct);
    }
  },

  async asyncData ({ store, route, context }) {
    await store.dispatch('ui/productLoader', true)

    if (context) context.output.cacheTags.add('product');

    const slug = route.params.slug;
    const product = await store.dispatch('product/loadProduct', {
      slug: slug,
      includeFields: modulesConfig.singleProduct.includeFields,
      excludeFields: modulesConfig.singleProduct.excludeFields,
      skipCache: true
    });

    const shopId = await getCurrentShopId()
    const discountProductIds = product[`markdown_items_${shopId}`] || []

    if (discountProductIds.length) {
      store.dispatch('product/loadDiscountProducts', { ids: discountProductIds })
    } else {
      store.dispatch('product/loadDiscountProducts', { ids: [] })
    }

    await Promise.allSettled([
      store.dispatch('ui/loadCatalogMenu'),
      store.dispatch(
        'product-extension/loadProductBreadcrumbs',
        { product }
      ),
      store.dispatch('product-extension/loadCharacteristicAttributes', { product }),
      store.dispatch('promoted/updateProductPromotionBanners', { promotionBannerIds: product?.promotion_banner_ids }),
      store.dispatch('product-review/loadReview', product.id)
    ])

    catalogHooksExecutors.productPageVisited(product);

    await store.dispatch('ui/productLoader', false)
  },
  beforeCreate () {
    registerModule(ReviewModule);
  },
  beforeMount () {
    this.$bus.$on('shipping-details-changed', this.updateCurrentProductHandler)
  },
  async mounted () {
    let currentProduct = this.getCurrentProduct;
    currentProduct.store = currentStoreView().storeCode;
    window.addEventListener('buyWithProductLoaded', this.getBuyWithOrInsteadProductSku)
    window.addEventListener('insteadBuyProductPageLoaded', this.getBuyWithOrInsteadProductSku)
    window.addEventListener('similarProductsLoaded', this.getSimilarProductsSku)
    await Promise.all([
      this.$store.dispatch('review/list', {
        productId: this.getOriginalProduct.id
      }),
      this.$store.dispatch('blog/getRelatedArticlesByproductId', { productId: this.getCurrentProduct.id })
    ]);
    this.$nextTick(() => this.addItemToRecentlyViewedItems(currentProduct))
  },
  async beforeRouteEnter (to, from, next) {
    if (isServer) {
      next();
    } else {
      next(async vm => {
        const { getCurrentProduct } = vm;
        const VIEW_ITEM = 'view_item';
        const VIEW_ITEM_ADS = 'view_item_ads';

        try {
          await vm.loadInStockVariants();
        } catch (e) {
          Logger.error('Error loading in stock variants: ', e);
        }

        try {
          vm.gtmProductsHandler(getCurrentProduct, VIEW_ITEM);
        } catch (e) {
          Logger.error('Error handling GTM products: ', e);
        }

        try {
          vm.gtmProductsHandlerAds(getCurrentProduct, VIEW_ITEM_ADS);
        } catch (e) {
          Logger.error('Error handling GTM products ads: ', e);
        }

        try {
          vm.admitadRetagProductPage(getCurrentProduct);
        } catch (e) {
          Logger.error('Error retagging product page: ', e);
        }
      });
    }
  },
  beforeDestroy () {
    window.removeEventListener('buyWithProductLoaded', this.getBuyWithOrInsteadProductSku)
    window.removeEventListener('insteadBuyProductPageLoaded', this.getBuyWithOrInsteadProductSku)
    window.removeEventListener('similarProductsLoaded', this.getSimilarProductsSku)
    this.$bus.$off('shipping-details-changed', this.updateCurrentProductHandler)
  },
  methods: {
    ...mapActions({
      addItemToRecentlyViewedItems: 'recently-viewed/addItem',
      loadInStockProductVariants: 'product-extension/loadInStockProductVariants',
      loadStockForCurrentProduct: 'product-extension/loadStockForCurrentProduct'
    }),
    async loadInStockVariants () {
      const uniqueIds = [...new Set(this.productVariantsIds)]
      this.loadInStockProductVariants({ ids: uniqueIds })
    },
    async configurableOptionCallback (variant) {
      const selectedConfiguration = Object.assign(
        { attribute_code: variant.type },
        variant
      );
      await filterChangedProduct(
        selectedConfiguration,
        this.$store,
        this.$router
      );
    },
    relatedProductsQty (value) {
      this.relatedProductLength = value
    },
    insteadProductsQty (value) {
      this.insteadProductsLength = value
    },
    getBuyWithOrInsteadProductSku () {
      this.esputnikBuyWithOrInsteadProductSku = getEsputnikProductIds('#' + this.buyWithOrInsteadProductWidgetId + ' .recommend-product-id')
      this.esUrlParamBuyWithOrInsteadProduct = getEsputnikUrl('#' + this.buyWithOrInsteadProductWidgetId + ' .recommend-product-id')
    },
    getSimilarProductsSku () {
      this.esputnikSimilarProductsSku = getEsputnikProductIds('#' + this.similarProductsWidgetId + ' .recommend-product-id')
      this.esUrlParamSimilarProducts = getEsputnikUrl('#' + this.similarProductsWidgetId + ' .recommend-product-id')
    },
    getBuyWithOrInsteadBlockIsVisible (isVisible) {
      this.buyWithOrInsteadBlockIsVisible = isVisible
    },
    getSimilarBlockIsVisible (isVisible) {
      this.similarBlockIsVisible = isVisible
    },
    async updateCurrentProductHandler () {
      return this.loadStockForCurrentProduct()
    }
  },
  metaInfo: metaProduct
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.product-list-section {

  @include for-desktop {
    padding: var(--section-padding, var(--spacer-2xl) 0 0 0);
  }

  ::v-deep {
    .o-section--center {
      @media (min-width: 1024px) and (max-width: 1215px) {
        padding: 0 var(--spacer-lg);
      }
    }
  }
}

::v-deep {
  .product__info {
    @include for-tablet {
      display: flex;
      flex-wrap: wrap;
    }

    @include for-desktop {
      width: 100%;
      max-width: 29.6875rem;
    }

    .sf-heading {
      @include for-mobile {
        padding-bottom: var(--spacer-3);
      }
    }

    .m-top-characteristics,
    .m-product-short-info {
      @include for-tablet {
        width: 100%;
      }
    }

    .price {
      @include for-mobile {
        margin-bottom: var(--spacer-sm);
      }

      .sf-price {
        &__regular {
          color: var(--black);

          @include for-mobile {
            white-space: nowrap;
            margin-right: var(--spacer-5);
          }
        }
      }

      &__regular {
        @include for-mobile {
          font-size: var(--font-size-26);
        }
      }
    }

    .m-product-add-to-cart {
      .sf-button {
        span {
          @include for-mobile {
            font-size: var(--font-size-18);
          }
        }
      }
    }
  }
}

::v-deep {
  .sf-heading {
    position: relative;
  }
}

#product {
  box-sizing: border-box;
  @include for-desktop {
    max-width: 1272px;
    margin: 0 auto;
  }

  .buy-with-product {
    box-shadow: -40vw 0 0 0 var(--light-gray), 40vw 0 0 0 var(--light-gray);
  }

  .buy-instead-product {
    box-shadow: -40vw 0 0 0 var(--loggia-light), 40vw 0 0 0 var(--loggia-light);
  }

  .buy-with-product,
  .buy-instead-product {
    position: relative;

    @include for-mobile {
      margin-left: 0;
      margin-right: 0;
    }

    ::v-deep .o-section {
      &--center {
        @include for-mobile {
          padding: 0;
        }

        @include for-desktop {
          padding: 0 var(--spacer-10);
        }

        .sf-link--primary {
          @include for-mobile {
            display: none;
          }

          @media (min-width: $tablet-min) {
            display: block;
          }
        }
      }

      &__heading {
        @include for-mobile {
          padding-left: var(--spacer-10);
          padding-right: var(--spacer-10);
        }
      }

      &--article-product {
        margin-bottom: 0 var(--spacer-100);

        @include only-mobile {
          margin-bottom: 0 var(--spacer-60);
        }
      }
    }
  }

  .buy-with-product,
  .buy-instead-product {
    @include for-mobile {
      padding-bottom: var(--spacer-35);
    }
    @include for-desktop {
      padding-bottom: var(--spacer-10);
      width: 100%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    ::v-deep {
      .glide__slide .sf-product-card {
        padding-bottom: var(--spacer-20);
      }
    }
  }

  .buy-instead-product {
    @include for-mobile {
      margin-top: 0;
    }
    ::v-deep .sf-heading__title--h3 {
      --heading-title-font-size: var(
          --section-heading-title-font-size,
          var(--font-size-20)
      );
      @media (min-width: $tablet-min) {
        --heading-title-font-size: var(
            --section-heading-title-font-size,
            var(--font-size-32)
        );
      }
    }

    &:after {
      @include for-desktop {
        background-color: var(--loggia-light);
      }
    }
  }

  ::v-deep .m-product-additional-info {
    .m-product-tabs {
      @include for-mobile {
        margin-bottom: 0;
      }

      .m-product-tab__content {
        @include for-mobile {
          padding-bottom: var(--spacer-50);
        }
      }
    }
  }

  .tm-section {
    ::v-deep {
      .o-section--center {
        padding: 0;

        .glide__slides {
          @include only-mobile {
            margin-left: var(--spacer-10);
          }
        }

        @media (min-width: $tablet-min) {
          max-width: 99%;
          margin: 0 auto;
        }
      }

      .o-section__heading {
        padding-inline: var(--spacer-10);

        @media (min-width: $tablet-min) {
          padding-inline: 0;
        }
      }
    }
  }
}

.sf-carousel__controls {
  --button-background: #fff !important;
}

.breadcrumbs {
  padding: var(--spacer-base) var(--spacer-base) var(--spacer-base)
    var(--spacer-sm);
}

::v-deep {
  .product__colors button {
    border: 1px solid var(--c-light);
  }
}

.banner {
  margin: var(--spacer-xl) 0;
  @include for-desktop {
    margin: var(--spacer-2xl) 0;
  }
}

.product_bottom_backgraund {
  background-color: var(--light-gray);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  margin-left: -50vw;
  left: 50%;
  z-index: -1;
}

.buy-with-product,
.buy-instead-product {

  ::v-deep .glide__slides {
    .sf-carousel-item {
      .sf-product-card {
        --product-card-height: 100%;
      }
    }
  }
}
</style>
