import { render, staticRenderFns } from "./discounted-product.vue?vue&type=template&id=6fbe4b91&scoped=true"
import script from "./discounted-product.vue?vue&type=script&lang=js"
export * from "./discounted-product.vue?vue&type=script&lang=js"
import style0 from "./discounted-product.vue?vue&type=style&index=0&id=6fbe4b91&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fbe4b91",
  null
  
)

export default component.exports