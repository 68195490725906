import config from 'config'

export default {
  computed: {
    attributes () {
      return this.characteristicAttributeCodes
        .map(code => this.buildAttributeObject(code, this.product?.all_attributes_list))
        .filter(attributeObj => attributeObj); // filter out null or undefined
    },
    attributesTop () {
      return this.characteristicAttributeCodes
        .map(code => this.buildAttributeObject(code, this.product?.top_attributes_list))
        .filter(attributeObj => attributeObj); // filter out null or undefined
    }
  },
  methods: {
    buildAttributeObject (code, list) {
      const attributes = list || {}
      const attribute = this.characteristicAttributes.find(attr => attr.attribute_code === code);

      if (!attribute || !attributes[code]) return;

      const label = attribute.frontend_label || attribute.default_frontend_label;
      const value = this.buildAttributeValue(attribute, attributes[code]);

      if (code === config.attributesCodes.ingredients) return;

      return {
        label: this.capitalizeFirstLetter(label),
        value,
        code: attribute.attribute_code
      };
    },
    buildAttributeValue (attribute, productCode) {
      let value = productCode.toString();

      if (this.isSelectable(attribute) && attribute.options) {
        const labels = this.isArray(productCode)
          ? this.buildLabelsForArray(productCode, attribute.options)
          : this.buildLabels(productCode, attribute.options);

        if (labels.length) value = [...new Set(labels)].join(', ');
      }

      return value;
    },
    isSelectable (attribute) {
      return ['select', 'multiselect'].includes(attribute.frontend_input);
    },
    isArray (value) {
      return Array.isArray(value);
    },
    buildLabelsForArray (productCode, options) {
      return productCode
        .map(val => this.buildLabels(val, options))
        .flat(); // to flatten the array of labels
    },
    buildLabels (productCode, options) {
      return options
        .filter(opt => opt?.value?.toString() === productCode.toString())
        .map(opt => opt?.label)
        .filter(label => label); // filter out null or undefined labels
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
}
