<template>
  <div
    id="m-product-additional-info"
    class="m-product-additional-info"
  >
    <div
      class="m-product-tabs"
      ref="productTabs"
    >
      <ul
        v-if="!isMobile"
        class="m-product-tabs__header"
      >
        <li
          class="m-product-tab__title"
          :class="{'m-product-tab__title--active': isActiveTab('description')}"
          @click="toggleTab('description')"
        >
          {{ $t('Information about product') }}
        </li>
        <li
          class="m-product-tab__title m-product-tab_reviews"
          :class="{'m-product-tab__title--active': isActiveTab('reviews')}"
          @click="toggleTab('reviews')"
        >
          {{ titleReviews }}
        </li>
        <li
          class="m-product-tab__title"
          :class="{'m-product-tab__title--active': isActiveTab('shops')}"
          @click="onShopsTabClick"
        >
          {{ $t('Availability in shops') }}
        </li>
      </ul>
      <SfHeading
        v-if="isMobile"
        :level="3"
        :title="$t('Information about product')"
        class="m-product-tab__title-mobile"
      />
      <div class="m-product-tabs__content">
        <div
          v-if="isActiveTab('description') || isMobile"
          class="m-product-tab__content"
        >
          <div class="m-product-tab-content">
            <div ref="descriptionItem" class="m-product-tab-content__left">
              <MProductCharacteristics :product="product" />
              <div class="m-product-description" v-if="product[attributeCodeCookingMethod]">
                <div class="m-product-description__title">
                  {{ $t('Cooking method') }}
                </div>
                <MShortText :text="product[attributeCodeCookingMethod]" />
              </div>
              <div class="m-product-description" v-if="product.description">
                <div class="m-product-description__title">
                  {{ $t('Description') }}
                </div>
                <MShortText :text="product.description" />
              </div>
              <div class="m-product-composition" v-if="productIngredients">
                <div class="m-product-description__title">
                  {{ $t('Composition') }}
                </div>
                <MShortText :text="productIngredients" />
              </div>
            </div>
            <div ref="fastBuy" class="m-product-tab-content__right" v-if="isDesktop">
              <no-ssr>
                <div ref="fastBuyInner">
                  <MProductMiniDetails
                    v-if="!isLoading"
                    :product="product"
                    :product-custom-options="productCustomOptions"
                    :product-stock="productStock"
                  />
                </div>
              </no-ssr>
            </div>
          </div>
        </div>
        <div
          class="m-product-tab__content m-product-tab__content_reviews"
          v-if="isActiveTab('reviews') || isMobile"
        >
          <SfHeading
            v-if="isMobile"
            :level="3"
            :title="titleReviews"
            class="m-product-tab__title-mobile-review"
          />
          <div class="m-product-tab-content">
            <div class="m-product-tab-content__left">
              <p
                v-if="reviewsCount === 0 && !openPopupAddReview"
                class="review__title-not-review"
              >
                {{ $t('There are no reviews yet. Be the first to leave') }}
              </p>
              <SfButton
                v-if="reviewsCount === 0 && !reviewFormOpen && !openPopupAddReview"
                class="sf-button--primary review__feedback"
                @click="handleOpenReviewForm"
              >
                {{ $t('Leave feedback') }}
              </SfButton>
              <MReviewForm
                v-if="reviewFormOpen"
                :current-user="currentUser"
                @submitReview="submitReview"
              />
              <div
                v-if="openPopupAddReview || openPopupAddAnswer"
                class="review-success"
              >
                <p class="review-success__title">
                  {{ isErrorSubmitReview ? $t("Something went wrong ...") : $t("Hello!") }}
                </p>
                <p
                  v-if="!isErrorSubmitReview"
                  class="review-success__subtitle"
                >
                  {{ $t("We are very grateful for your feedback, we are already working on it. It is important for us to know your opinion!") }}
                </p>
              </div>
              <div class="review-header">
                <AProductRating
                  v-if="reviewsCount"
                  @handleOpenReviewForm="handleOpenReviewForm"
                  :review="ratingProduct"
                  :open-form="reviewFormOpen"
                  :success-form="openPopupAddReview"
                >
                  {{ $t('Leave me review') }}
                </AProductRating>
              </div>
              <MReviewList
                v-show="reviewsCount"
                :reviews="getReviewsProduct"
                @upvoteReview="upvoteReview"
                @downvoteReview="downvoteReview"
                @answerReview="answerReview"
              />
            </div>
            <div class="m-product-tab-content__right" v-if="isDesktop">
              <no-ssr>
                <lazy-hydrate when-visible>
                  <MProductMiniDetails
                    v-if="!isLoading"
                    :product="product"
                    :product-custom-options="productCustomOptions"
                    :product-stock="productStock"
                  />
                </lazy-hydrate>
              </no-ssr>
            </div>
          </div>
        </div>
        <div
          v-if="isActiveTab('shops') || isMobile"
          class="m-product-tab__content"
        >
          <SfHeading
            v-if="isMobile"
            :level="3"
            :title="$t('Availability in shops')"
            class="m-product-tab__title-mobile-shops"
          />
          <div class="m-product-tab-content shops-tab-content">
            <no-ssr>
              <OShops
                page-type="product"
                :stock-shops="inStockInShops"
                :cities="cities"
                :shops="shops"
                :current-city="currentCity"
                :selected-city="selectedCity"
                :general-loading="loading.general"
                :shops-loading="loading.shops"
                :filter="filter"
                @change-city="onChangeCity"
              />
            </no-ssr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ModalList } from 'theme/store/ui/modals';
import { mapState, mapActions, mapGetters } from 'vuex';
import { SfHeading, SfButton } from '@storefront-ui/vue';
import AProductRating from 'theme/components/atoms/a-product-rating';
import MProductCharacteristics from 'theme/components/molecules/m-product-characteristics';
import MReviewList from 'theme/components/molecules/m-review-list';
import MReviewForm from 'theme/components/molecules/m-review-form';
import MProductMiniDetails from 'theme/components/molecules/m-product-mini-details';
import MShortText from 'theme/components/molecules/m-short-text';
import OShops from 'theme/components/organisms/o-shops';
import LazyHydrate from 'vue-lazy-hydration';
import DeviceType from 'theme/mixins/DeviceType';
import { isServer } from '@vue-storefront/core/helpers';
import config from 'config';
import NoSSR from 'vue-no-ssr';
import ShippingShopsMixin from '$modules/shipping/mixins/shipping-shops-mixin';

export default {
  name: 'MProductAdditionalInfo',
  components: {
    AProductRating,
    SfButton,
    MProductCharacteristics,
    MReviewList,
    MReviewForm,
    MProductMiniDetails,
    OShops,
    LazyHydrate,
    MShortText,
    SfHeading,
    'no-ssr': NoSSR
  },
  mixins: [DeviceType, ShippingShopsMixin],
  props: {
    isLoading: {
      type: Boolean,
      default: () => false
    },
    reviews: {
      type: Array,
      default: () => []
    },
    attributes: {
      type: Array,
      default: () => []
    },
    product: {
      type: Object,
      default: () => ({})
    },
    productCustomOptions: {
      type: Object,
      default: () => ({})
    },
    productStock: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      activeTab: 'description',
      attributeCodeCookingMethod: config.attributeCodes ? config.attributeCodes.cookingMethod : 'cooking_method',
      reviewFormOpen: false,
      openPopupAddReview: false,
      openPopupAddAnswer: false,
      isErrorSubmitReview: false
    }
  },
  computed: {
    ...mapState({
      isReviewProductTab: state => state.ui.isReviewProductTab
    }),
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      getReviewsProduct: 'product-review/getReviewsProduct',
      currentUser: 'user/currentUser'
    }),
    reviewsCount () {
      return this.getReviewsProduct.length;
    },
    productIngredients () {
      return this.product?.all_attributes_list?.[config.attributesCodes.ingredients] ||
        this.product?.top_attributes_list?.[config.attributesCodes.ingredients] || ''
    },
    inStockInShops () {
      return this.product.stock_shop || []
    },
    ratingProduct () {
      return this.product.hasOwnProperty('aw_review_rating') ? this.product.aw_review_rating : 0;
    },
    titleReviews () {
      return this.reviewsCount > 0 ? `${this.$t('Testimonials')} (${this.reviewsCount})` : this.$t('Testimonials');
    }
  },
  watch: {
    isReviewProductTab (value) {
      if (value && !this.$refs.reviewTab.isActive) {
        this.$refs.productTabs.toggle(this.$refs.reviewTab._uid);
      }
    },
    'getShippingDetails.shopId': {
      immediate: true,
      handler: async function () {
        try {
          if (isServer) return

          this.options.isEnabled = true

          this.setFilters({
            city: this.getShippingDetails?.cityDetails?.id || this.getDefaultCity?.id
          })

          await this.loadShops()
        } catch (e) {
        } finally {
          this.loading.general = false
        }
      }
    }
  },
  created () {
    if (!isServer) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  beforeDestroy () {
    this.$store.commit('ui/setReviewProductTab', false);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      this.heightOfHeader = 60;
      this.heightOfFastBlock = 426;

      if (this.$refs.descriptionItem?.clientHeight > this.$refs.fastBuyInner?.clientHeight) {
        if (
          (this.$refs.descriptionItem.getBoundingClientRect().top - this.heightOfHeader < 0) &&
          (this.$refs.descriptionItem.getBoundingClientRect().bottom - this.heightOfHeader - this.heightOfFastBlock > 0)
        ) {
          this.$refs.fastBuy.classList.add('sticky');
        } else {
          this.$refs.fastBuy.classList.remove('sticky');
        }

        if (
          (this.$refs.descriptionItem.getBoundingClientRect().bottom - this.heightOfHeader - this.heightOfFastBlock < 0) &&
          (this.$refs.descriptionItem.getBoundingClientRect().bottom - this.heightOfHeader > 0)
        ) {
          this.$refs.fastBuy.classList.add('absolute');
        } else {
          this.$refs.fastBuy.classList.remove('absolute');
        }
      }
    },
    ...mapActions({
      openModal: 'ui/openModal'
    }),
    handleOpenReviewForm () {
      if (this.isLoggedIn) {
        this.reviewFormOpen = true;
      } else {
        this.$store.dispatch('ui/hideBottomNavigation', false)
        this.openModal({ name: ModalList.Auth, payload: 'auth' })
      }
    },
    async submitReview (data) {
      this.reviewFormOpen = false;
      this.openPopupAddReview = true;
      const review = {
        'created_at': new Date(),
        'email': data.email,
        'rating': data.reviewStars * 20,
        'nickname': data.firstname,
        'product_id': this.product.id,
        'content': data.review
      };

      try {
        await this.$store.dispatch('product-review/sendReview', review)
      } catch (error) {
        console.log(error);
        this.isErrorSubmitReview = true;
      } finally {
        setTimeout(() => {
          this.openPopupAddReview = false;
        }, 3000);
      }
    },

    upvoteReview (id) {
      this.$store.dispatch('product-review/upvoteReview', id)
    },

    downvoteReview (id) {
      this.$store.dispatch('product-review/downvoteReview', id)
    },

    async answerReview (review) {
      this.openPopupAddAnswer = true;
      try {
        await this.$store.dispatch('product-review/answerReview', review);
      } catch (error) {
        console.log(error);
        this.isErrorSubmitReview = true;
      } finally {
        const scrollTo = this.$refs.productTabs.clientHeight;
        window.scrollTo({
          top: scrollTo,
          left: 0,
          behavior: 'smooth'
        });

        setTimeout(() => {
          this.openPopupAddAnswer = false
        }, 3000);
      }
    },
    toggleTab (tab) {
      this.activeTab = tab
    },
    isActiveTab (tab) {
      return tab === this.activeTab
    },
    onShopsTabClick () {
      this.toggleTab('shops')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

.m-product-additional-info {
  padding: 0 var(--spacer-10);

  @include for-desktop {
    padding: 0;
  }
}

.m-product {
  &-tabs {
    max-width: var(--product-tabs-width);
    width: 100%;
    margin: var(--spacer-50) auto var(--spacer-2xl);

    @include for-desktop {
      margin-top: var(--spacer-50);
      --tabs-content-tab-padding: var(--spacer-xl) 0 0 0;
    }

    &__header {
      margin: 0;
      display: flex;
      justify-content: space-around;
      list-style: none;
      padding: 6px;
      background: var(--color-solitude);
      border-radius: 10px;
    }
  }

  &-tab {
    &__title {
      padding: var(--spacer-15) 0;
      color: var(--orange);
      border-radius: 7px;
      flex: 0 0 33%;
      cursor: pointer;
      text-align: center;
      font-weight: var(--font-medium);
      font-size: var(--font-size-20);

      &--active {
        background: var(--white);
        color: var(--dark-gray);
      }

      &-mobile {
        margin: 0;
        padding: 0;
        text-align: left;

        ::v-deep .sf-heading__title {
          @include header-title;

          @media (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: var(--font-size-20);
            --heading-title-font: var(--font-size-20);
            height: var(--spacer-50);
            line-height: var(--spacer-50);
            border-radius: var(--spacer-10);
            background-color: var(--white);
          }
        }

        @media (min-width: $tablet-min) and (max-width: $tablet-max) {
          background-color: var(--light-gray);
          text-align: center;
          padding: var(--spacer-6);
          border-radius: var(--spacer-10);
        }
      }
    }

    &__content {
      padding: var(--spacer-40) 0 0;

      @include for-mobile {
        padding-top: var(--spacer-10);
      }

      &-title-mobile {
        font-weight: var(--font-medium);
        font-size: var(--font-size-20);
        line-height: var(--font-size-25);
        color: var(--black);
        padding-bottom: var(--spacer-10);
      }

      .shops-tab-content {
        @media (max-width: $mobile-max) {
          flex-direction: column!important;
        }

        @include for-tablet {
          position: relative;
          margin-top: var(--spacer-30);
        }
      }
    }

    &__content:not(:first-child) {
      @media (max-width: $mobile-max) {
        padding-top: var(--spacer-40);
        border-top: 3px solid var(--light-gray);
      }
    }

    &__content:last-child {
      @media (max-width: $mobile-max) {
        padding-bottom: var(--spacer-15) !important;
      }
    }

    &-content {
      display: flex;
      flex-direction: row;

      &__left {
        width: 100%;

        @include for-desktop {
          flex-basis: 28.3125rem;
          min-width: 28.3125rem;
        }

      }

      &__right {
        @include for-desktop {
          flex-basis: calc(100% - 28.3125rem);
          min-width: calc(100% - 28.3125rem);
        }
      }
    }
  }
}

.review {
  &-header {
    display: flex;
    justify-content: space-between;
  }

  &__title-not-review {
    margin: 0 0 var(--spacer-25);
    font-size: var(--font-size-15);
    color: var(--dark-gray);

    @include for-desktop {
      margin: 0 0 var(--spacer-30);
    }
  }

  &__feedback {
    width: 100%;
    font-size: var(--font-size-18);
    line-height: var(--spacer-24);

    @include for-desktop {
      font-size: var(--font-size-14);
      line-height: normal;
      padding: var(--spacer-12) var(--spacer-16);
      width: auto;
    }

    &:hover {
      background-color: var(--orange-hover);
    }
  }

  &-success {
    margin-bottom: var(--spacer-20);
    height: px2rem(400);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--light-gray);
    padding: 0 15px;

    @include for-desktop {
      height: px2rem(380);
    }

    &__title {
      margin: 0;
      font-size: var(--font-size-24);
      color: var(--black);
    }

    &__subtitle {
      margin: var(--spacer-24) 0 0;
      font-size: var(--font-size-14);
      color: var(--black);
    }
  }
}

.m-product-description {
  color: var(--black);

  &__title {
    margin-top: var(--spacer-48);
    margin-bottom: var(--spacer-15);
    font-weight: var(--font-medium);
    font-size: var(--font-size-16);
    color: var(--black);
  }
}

.sticky {
  @include for-desktop {
    min-width: auto;
    flex-basis: auto;
    position: fixed;
    top: 8rem;
    left: calc(50% + var(--spacer-57));
  }
}

.absolute {
  position: relative;

  .m-product-mini-details {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.m-product-tab__title-mobile-review,
.m-product-tab__title-mobile-shops {
  padding-bottom: var(--spacer-10);
  text-align: left;

  ::v-deep {
    .sf-heading__title {
      @include header-title;
    }
  }
}
</style>
