<template>
  <MCarouselItems
    :items="posts"
    :slider-settings="sliderSettings"
    carousel-class="articles-carousel"
  >
    <template slot-scope="{ item }">
      <MBlogPost
        :post-id="item.id"
        :is-lazy="false"
        :img="item.featured_img"
        :views="item.views_count"
        :title="item.title"
        :date="item.publish_time"
        :like="item.like"
        :dislike="item.dislike"
        :identifier="item.identifier"
      />
    </template>
  </MCarouselItems>
</template>
<script>
import { BREAKPOINT_XSM, BREAKPOINT_SM, BREAKPOINT_MD, BREAKPOINT_LG } from 'theme/helpers/breakpoints';
import MCarouselItems from 'theme/components/molecules/m-carousel-items'
import MBlogPost from 'theme/components/molecules/m-blog-post.vue'

export default {
  name: 'MArticleCarouselProductPage',
  props: {
    posts: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      sliderSettings: {
        type: 'slider',
        animationDuration: 400,
        animationTimingFunc: 'ease-in-out',
        perView: 4,
        gap: 10,
        dragThreshold: false,
        breakpoints: {
          [BREAKPOINT_LG - 1]: {
            perView: 4
          },
          [BREAKPOINT_MD - 1]: {
            perView: 3
          },
          [BREAKPOINT_SM - 1]: {
            perView: 2.3
          },
          [BREAKPOINT_XSM - 1]: {
            perView: 2,
            gap: 12,
            swipeThreshold: true,
            dragThreshold: false
          }
        }
      }
    }
  },
  components: {
    MCarouselItems,
    MBlogPost
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";

  .articles-carousel {
    ::v-deep {
      .sf-image img {
        @media (max-width: $mobile-max) {
          width: var(--image-width);
          max-width: 100%;
          height: var(--image-height, auto);
        }
      }

      .post__description {
        max-height: 5rem;
      }
    }
  }
</style>
