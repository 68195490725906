<template>
  <div
    class="a-product-rating"
    data-transaction-name="PR - Rating"
    @click="$emit('click')"
  >
    <div class="product__rating">
      <p class="product__count">
        {{ count }}
      </p>
      <SfORating
        :score="count"
        :max="max"
        :summary-rating="true"
      />
      <SfButton
        v-if="!openForm && !successForm"
        class="product-feedback"
        data-transaction-name="PR - Review Form"
        @click="openReviewForm"
      >
        <SfIcon
          class="product-feedback__icon"
          icon="review"
        >
          <svg
            width="20"
            height="20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path xmlns="http://www.w3.org/2000/svg" d="M8 0H12C14.1217 0 16.1566 0.842855 17.6569 2.34315C19.1571 3.84344 20 5.87827 20 8C20 10.1217 19.1571 12.1566 17.6569 13.6569C16.1566 15.1571 14.1217 16 12 16V19.5C7 17.5 0 14.5 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0Z" fill="#EB6747" />
          </svg>
        </SfIcon>

        {{ $t('Leave feedback') }}
      </SfButton>
    </div>
  </div>
</template>

<script>
import SfORating from 'theme/components/storefront-override/SFORating';
import { SfButton, SfIcon } from '@storefront-ui/vue';

export default {
  components: {
    SfORating,
    SfButton,
    SfIcon
  },
  props: {
    review: {
      type: Number,
      default: 0
    },
    openForm: {
      type: Boolean,
      default: false
    },
    successForm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      max: 5
    }
  },
  computed: {
    count () {
      return this.review / 20;
    }
  },
  methods: {
    openReviewForm () {
      this.$emit('handleOpenReviewForm');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.a-product-rating {
  width: 100%;
}

.product {
  &__rating {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacer-14);

    ::v-deep {
        .sf-rating__icon {
          margin: 0 px2rem(1);
        }
    }
  }

  &__count {
    margin: 0 var(--spacer-xs) 0 0;
    font-size: var(--font-size-21);
    line-height: var(--spacer-17);
    color: var(--orange);
  }

  &-feedback {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    padding: var(--spacer-12) var(--spacer-16);
    font-size: var(--font-size-14);
    color: var(--white);
    background-color: var(--orange);

    &:hover {
      color: var(--orange-hover);

      @media (min-width: $tablet-min) {
        background-color: var(--orange-hover);
      }
    }

    @include only-mobile {
      padding: 0;
      font-size: var(--font-size-14);
      font-weight: var(--font-normal);
      color: var(--orange);
      background-color: var(--white);
    }

    &__icon {
      display: none;
      margin-right: var(--spacer-5);

      @include only-mobile {
        display: block;
      }
    }
  }
}

::v-deep {
  .sf-rating__icon {
    --icon-color: var(--yellow-light);
  }
  .sf-rating__icon--negative {
    --icon-color: var(--c-gray-variant);
  }
}
</style>
