<template>
  <div class="a-product-attribute">
    <SfImage
      :src="getProductAttributeImagePath(icon, 0, 0)"
      width="60"
      height="60"
    />
    <div class="a-product-attribute__label">
      {{ label }}
    </div>
  </div>
</template>

<script>
import { getProductAttributeImagePath } from 'theme/helpers';
import { SfImage } from '@storefront-ui/vue';
export default {
  name: 'AProductAttributeImage',
  components: { SfImage },
  props: {
    icon: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    getProductAttributeImagePath
  }
}
</script>

<style lang="scss" scoped>
.a-product-attribute {
  width: 60px;

  &__label {
    margin-top: 4px;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }
}
</style>
