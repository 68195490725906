<template>
  <SfPrice
    :regular="regularPrice"
    :special="price.special"
  />
</template>
<script>
import { SfPrice } from '@storefront-ui/vue';
import { getProductPrice, formatCurrencyWithSpace } from 'theme/helpers'
export default {
  name: 'AProductPrice',
  components: {
    SfPrice
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    customOptions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    price () {
      return getProductPrice(this.product, this.customOptions)
    },
    regularPrice () {
      const price = getProductPrice(this.product, this.customOptions);
      const regularPrice = price.regular;
      const specialPrice = price.special;

      return (regularPrice && specialPrice)
        ? formatCurrencyWithSpace(regularPrice)
        : regularPrice
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.sf-price {
  display: block;
}
</style>
