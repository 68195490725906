<template>
  <div class="review-form">
    <p class="review-form__title">
      {{ $t('Add review') }}
    </p>
    <div class="review-form-rating__wrap">
      <div class="review-form-rating">
        <p class="review-form-rating__title">
          {{ $t('Rate this item:') }}
        </p>
        <div class="review-form-rating-stars">
          <SfInput
            v-model="reviewStars"
            name="reviewStars"
            :label="$t('reviewStars')"
            :valid="!$v.reviewStars.$error"
            :error-message="$t('Please provide valid e-mail address.')"
            class="review-form__element review-form__element_stars"
            @input="$v.reviewStars.$touch()"
          />
          <SfIcon
            v-for="index in stars"
            :key="index"
            size="0.875rem"
            class="sf-rating__icon sf-rating__icon--negative"
            icon="star"
            data-transaction-name="Review - Stars"
            @click="setReviewStars(index)"
          >
            <svg
              width="14"
              height="13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 .33 9.19 4l4.15.95-2.8 3.21.38 4.24L7 10.72l-3.92 1.67.38-4.24-2.8-3.21 4.15-.95L7 .33Z" stroke="#FAD656" />
            </svg>
          </SfIcon>
          <div class="review-form-rating-stars review-form-rating-stars--active">
            <SfIcon
              v-for="index in reviewStars"
              :key="index"
              size="0.875rem"
              class="sf-rating__icon"
              icon="star"
              data-transaction-name="Review - Stars"
              @click="setReviewStars(index)"
            >
              <svg
                width="14"
                height="13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 .33 9.19 4l4.15.95-2.8 3.21.38 4.24L7 10.72l-3.92 1.67.38-4.24-2.8-3.21 4.15-.95L7 .33Z" stroke="#FAD656" fill="#FAD656" />
              </svg>
            </SfIcon>
          </div>
        </div>
      </div>
      <div
        v-if="$v.reviewStars.$error"
        class="custom-error-message"
      >
        {{$t('It is necessary to evaluate')}}
      </div>
    </div>
    <form>
      <SfInput
        v-model="firstname"
        name="first-name"
        :label="firstNameLabel"
        :valid="!$v.firstname.$error"
        :error-message="$v.firstname.required ? $t('Please use only letters or symbols') : $t('Field is required')"
        class="sf-input--filled review-form__element"
        data-transaction-name="Review - First Name"
        @input="$v.firstname.$touch()"
      />
      <SfInput
        v-model="email"
        name="email"
        :label="$t('E-mail')"
        :valid="!$v.email.$error"
        :error-message="$v.email.required ? $t('Please provide valid e-mail address.') : $t('Field is required')"
        class="sf-input--filled review-form__element"
        data-transaction-name="Review - Email"
        @input="$v.email.$touch()"
      />
      <ATextarea
        :placeholder="$t('Review text')"
        :alternative-placeholder="$t('Review text')"
        v-model="review"
        :min-height="90"
        type="text"
        :valid="!$v.review.$error"
        :error-message="$t('Field is required')"
        id="review"
        name="review"
        :class="{'custom-input--invalid': $v.review.$error}"
        class="review-form__element"
        @input="$v.review.$touch()"
      />
      <div
        v-if="$v.review.$error"
        class="custom-error-message"
      >
        {{$t('Field is required')}}
      </div>

      <SfButton
        class="sf-button--primary review-form__submit"
        data-transaction-name="Review - Submit"
        @click.prevent="submitForm()"
      >
        {{ $t('Publish') }}
      </SfButton>
    </form>
  </div>
</template>

<script>
import { SfButton, SfIcon } from '@storefront-ui/vue';
import { isReviewStarsValid, isNameValid } from 'theme/helpers/validation';
import SfInput from 'theme/components/storefront-override/SfInput';
import ATextarea from 'theme/components/atoms/a-textarea';
import { email, required } from 'vuelidate/lib/validators';

export default {
  name: 'MReviewForm',
  components: {
    SfInput,
    SfButton,
    ATextarea,
    SfIcon
  },
  data () {
    return {
      email: '',
      firstname: '',
      firstNameLabel: this.$t('First name'),
      review: '',
      reviewStars: 0,
      stars: 5
    };
  },
  props: {
    currentUser: {
      type: Object,
      default: () => ({})
    }
  },
  validations: {
    reviewStars: {
      isReviewStarsValid
    },
    email: {
      required,
      email
    },
    firstname: {
      required,
      isNameValid
    },
    review: {
      required
    }
  },
  mounted () {
    this.initUserInfo();
  },
  methods: {
    setReviewStars (value) {
      this.reviewStars = value;
    },
    initUserInfo () {
      this.email = this.currentUser.email;
      this.firstname = this.currentUser.firstname;
    },
    async submitForm () {
      await this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const data = {
        email: this.email,
        firstname: this.firstname,
        review: this.review,
        reviewStars: this.reviewStars
      }

      this.$emit('submitReview', data);
    }
  }
}

</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.review-form {
  margin: 0 0 var(--spacer-20);

  &__title {
    margin: 0;
    font-size: var(--font-size-16);
    font-weight: var(--font-medium);

    @include for-desktop {
      font-size: var(--font-size-20);
    }
  }

  &-rating__wrap {
    margin: var(--spacer-14) 0 var(--spacer-20);
  }

  &-rating {
    display: flex;
    align-items: center;
    margin: var(--spacer-5) 0;

    &__title {
      margin: 0;
      font-size: var(--font-size-13);
      color: var(--dark-gray);
    }

    &-stars {
      margin-left: var(--spacer-10);
      display: flex;
      align-items: center;
      position: relative;

      &--active {
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
      }

      ::v-deep {
        .sf-rating__icon {
          cursor: pointer;
          margin: 0 px2rem(1);
        }
      }
    }
  }

  &__element {
    margin-bottom: var(--spacer-10);
    border: none;
    --input-background: var(--light-gray);
    --input-padding: var(--spacer-27) var(--spacer-15) var(--spacer-10);
    --input-font-line-height: var(--spacer-18);
    --input-border: none;
    --input-label-font-size: var(--font-size-15);
    --input-label-font-line-height: var(--font-size-15);
    --input-bar-display: none;

    &_stars {
      display: none;
    }
  }

  ::v-deep {
    .a-textarea__clear {
       display: none;
    }

    .a-textarea__field {
      margin-bottom: var(--spacer-10);
      border: 1px solid var(--white);
      background-color: var(--light-gray);
      padding: var(--spacer-20) var(--spacer-15) var(--spacer-10);

      &:focus {
        padding: var(--spacer-30) var(--spacer-15) var(--spacer-10);

        & ~ * {
          font-size: var(--font-size-12);
          top: var(--spacer-10);
        }
      }
    }

    .a-textarea__label {
      top: var(--spacer-23);
      font-size: var(--font-size-15);
    }

    .a-textarea--has-text {
      .a-textarea__label {
        font-size: var(--font-size-12);
        top: var(--spacer-10);
      }

      .a-textarea__field {
        padding: var(--spacer-30) var(--spacer-15) var(--spacer-10);
      }

      .a-textarea__field:focus {
        border: 1px solid var(--orange);
      }
    }

    .sf-input {
      &--has-text {
        .sf-input__label {
          --input-label-top: 23%;
          --input-label-color: var(--dark-gray);
          --input-label-font-size: var(--font-size-12);
        }
      }

      input {
        border: 1px solid var(--white);

        &:focus {
          & ~ * {
            --input-label-top: 23%;
            --input-label-color: var(--dark-gray);
            --input-label-font-size: var(--font-size-12);
          }
        }
      }

      &--invalid {
        input {
          border: 1px solid var(--red-main);
        }
      }

      &__error-message {
        height: unset;
      }
    }

    .sf-input--filled input:hover {
      border-color: var(--light-gray);
    }

    .sf-input--filled input:focus {
      border: 1px solid var(--orange);
    }

    .custom-error-message {
      color: var(--input-error-message-color, var(--c-danger));
      height: calc(var(--font-xs) * 1.2);
      font: var(--input-error-message-font, var(--input-error-message-font-weight, var(--font-medium)) var(--input-error-message-font-size, var(--font-xs))/var(--input-error-message-font-line-height, 1.2) var(--input-error-message-font-family, var(--font-family-secondary)));
    }
    .custom-input--invalid {
      .a-textarea__field {
        border: 1px solid var(--red-main);
      }
    }
  }

  &__submit {
    width: 100%;
    margin-top: var(--spacer-20);
    font-size: var(--font-size-18);
    line-height: var(--spacer-24);

    @include for-desktop {
      width: auto;
    }

    &:hover {
      background-color: var(--orange-hover);
    }

    &:disabled {
      pointer-events: none;
    }
  }
}

</style>
