<template>
  <div class="m-review-list">
    <MReviewItem
      v-for="(review, i) in pageReviews"
      :key="i"
      :review="review"
      @upvoteReview="upvoteReview"
      @downvoteReview="downvoteReview"
      @answerReview="answerReview"
    />
    <SfOPagination
      v-if="total > 1"
      :current="currentPage"
      :total="total"
      :scroll-top="false"
      :per-page="visible"
      page-param-name="page"
      class="product__reviews-pagination"
    />
  </div>
</template>

<script>
import SfOPagination from 'theme/components/storefront-override/SfOPagination';
import MReviewItem from 'theme/components/molecules/m-review-item';
export default {
  name: 'MReviewList',
  components: {
    SfOPagination,
    MReviewItem
  },
  data () {
    return {
      currentPage: 1
    }
  },
  props: {
    reviews: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Number,
      default: 3
    }
  },
  computed: {
    total () {
      return Math.ceil(this.reviews.length / this.visible)
    },
    pageReviews () {
      const startIndex = (this.currentPage - 1) * this.visible
      const endIndex = startIndex + this.visible
      return this.reviews.slice(startIndex, endIndex)
    }
  },
  methods: {
    setCurrentPage (newPage) {
      this.currentPage = newPage
    },

    upvoteReview (id) {
      this.$emit('upvoteReview', id);
    },

    downvoteReview (id) {
      this.$emit('downvoteReview', id);
    },

    answerReview (review) {
      this.$emit('answerReview', review);
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        const page = to.query.page || to.params.page
        if (page) {
          this.setCurrentPage(parseInt(page) || 1);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.product__reviews-pagination {
  justify-content: flex-end;
  margin-top: var(--spacer-30);
  padding: var(--spacer-20) 0;
  background-color: var(--light-gray);

  ::v-deep {
    .products__load-more {
      display: none;
    }

    .sf-pagination__item {
      color: var(--orange);

      &--current, &--was  {
        z-index: 1;
      }
    }
  }
}

</style>
