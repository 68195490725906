<template>
  <component :is="'script'" v-html="jsonld" type="application/ld+json" />
</template>

<script>
import { mapGetters } from 'vuex';
import { getProductImagePath } from 'theme/helpers';
import { SIZE_PRODUCT_GALLERY } from 'theme/components/molecules/m-product-gallery';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'JsonLdProduct',
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      getReviewsProduct: 'product-review/getReviewsProduct'
    }),
    jsonld () {
      return JSON.stringify(this.content, null, 2);
    },
    // TODO: Change JSON-LD structure as soon as product reviews will be ready (VAR-867)
    content () {
      const jsonProductInfo = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        'description': this.product.description,
        'name': this.product.name,
        'image': getProductImagePath(
          this.product.sku,
          SIZE_PRODUCT_GALLERY.desktop.width,
          SIZE_PRODUCT_GALLERY.desktop.height
        ),
        model: this.product.sku
      };
      const jsonBrandInfo = {
        'brand': {
          '@type': 'Brand',
          'name': this.product?.brand_data?.name
        }
      };
      const jsonOfferInfo = {
        'offers': {
          '@type': 'Offer',
          'price': this.getPrice,
          'priceCurrency': currentStoreView().i18n.currencyCode
        }
      };
      const jsonAggregateRating = this.getReviewsProduct.length > 0
        ? {
          'aggregateRating': {
            '@type': 'AggregateRating',
            'ratingValue': this.product.aw_review_rating / 20,
            'reviewCount': this.product.aw_review_count
          }
        }
        : {};
      const reviewList = this.getReviewsProduct.map(item => {
        return {
          '@type': 'Review',
          'author': item.nickname,
          'datePublished': this.getFormatDate(item.created_at),
          'description': item.content,
          'reviewRating': {
            '@type': 'Rating',
            'bestRating': '5',
            'ratingValue': '1',
            'worstRating': '1'
          }
        }
      });
      const jsonReviewList = reviewList.length > 0
        ? {
          'review': reviewList
        }
        : {};

      return {
        ...jsonProductInfo,
        ...jsonAggregateRating,
        ...jsonOfferInfo,
        ...jsonReviewList,
        ...jsonBrandInfo
      };
    },
    getPrice () {
      const { sqpp } = this.product;

      return (sqpp?.special_price)
        ? sqpp.special_price
        : sqpp.price
    }
  },
  methods: {
    getFormatDate (date) {
      return new Date(date).toISOString().split('T')[0];
    }
  }
}
</script>
